import React, { useState } from "react";
import { FormInput } from "../../utils/forms/FormComponents";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import { showErrorMessage, showToastMessage } from "../../utils/helpers";
import { Button } from "@progress/kendo-react-buttons";
import LoadingPanel from "../common/LoadingPanel";
// import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import CustomerBlockingModel from "./CustomerBlockingModel";
const CustomerManagementHome = () => {
  // const [expanded, setExpanded] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [customerDetails, setCustomerDetails] = useState({});
  // const [customerLogsPayload, setCustomerLogsPayload] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const fetchCustomerDetails = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_USER_BASE_URL +
          APIConfig.customerManagement.getCustomerDetails +
          "?mobileNumber=" +
          mobileNumber
      )
      .then((data) => {
        setLoading(false);
        if (data?.data) {
          setCustomerDetails({ ...data?.data });
          // setCustomerLogsPayload({
          //   resources: [
          //     {
          //       type: "dashboard",
          //       id: process.env.REACT_APP_SUPERSET_CUSTOMER_LOGS_ID,
          //     },
          //   ],
          //   rls: [
          //     { clause: `mobile_number = '\''${data?.data?.mobileNumber}'\''` },
          //   ],
          // });
        }
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  // const handleSelect = (event) => {
  //   if (event.expandedItems) {
  //     setExpanded(event.expandedItems);
  //   }
  // };

  const openBlockingModal = () => {
    setModalOpen(true);
  };

  const closeBlockingModal = () => {
    setModalOpen(false);
  };

  const handleCustomerBlocking = async (reason, subReason) => {
    closeBlockingModal();
    setCustomerDetails(false);
    let payload = {
      mobileNumber: mobileNumber,
      reason: reason,
      subReason: subReason,
    };
    await axiosService
      .put(
        process.env.REACT_APP_USER_BASE_URL +
          APIConfig.customerManagement.blockCustomer,
        JSON.stringify(payload),
        { "Content-Type": "application/json" }
      )
      .then((data) => {
        setCustomerDetails({
          ...customerDetails,
          blocked: true,
        });
        showToastMessage(`${customerDetails.fullName} is blocked`);
      })
      .catch((error) => {
        setCustomerDetails(false);
        showErrorMessage(error);
      });
  };

  const handleCustomerUnblocking = async () => {
    setCustomerDetails(false);
    await axiosService
      .put(
        process.env.REACT_APP_USER_BASE_URL +
          APIConfig.customerManagement.unBlockCustomer(mobileNumber),
        { "Content-Type": "application/json" }
      )
      .then((data) => {
        setCustomerDetails({
          ...customerDetails,
          blocked: false,
        });
        showToastMessage(`${customerDetails.fullName} is unblocked`);
      })
      .catch((error) => {
        setCustomerDetails(false);
        showErrorMessage(error);
      });
  };
  return (
    <div className="customer-management-home">
      <div className="search-customer">
        <div className="row">
          <div className="col-lg-2">
            <span>Search By Phone Number:</span>
          </div>
          <div className="col-lg-2">
            <FormInput
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>
          <div className="col-lg-2">
            <Button
              themeColor={"primary"}
              onClick={fetchCustomerDetails}
              disabled={loading}
            >
              Search
            </Button>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-2"></div>
          {Object.keys(customerDetails)?.length > 0 && (
            <div className="col-lg-2">
              {customerDetails?.blocked ? (
                <Button
                  themeColor={"error"}
                  onClick={handleCustomerUnblocking}
                  disabled={loading}
                >
                  Unblock User
                </Button>
              ) : (
                <Button
                  themeColor={"error"}
                  onClick={openBlockingModal}
                  disabled={loading}
                >
                  Block User
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <br />
      {loading ? (
        <LoadingPanel />
      ) : (
        <>
          {Object.keys(customerDetails)?.length > 0 && (
            <>
              <div className="customer-details">
                <div className="row">
                  {/* Customer Details */}
                  <div className="col-md-6">
                    <h5>Customer Details</h5>
                    <div className="row my-2">
                      <div className="col-md-6">Customer Name :</div>
                      <div className="col-md-6">
                        {customerDetails?.fullName}
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-6">Customer Email Id :</div>
                      <div className="col-md-6">{customerDetails?.emailId}</div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-6">Customer Mobile :</div>
                      <div className="col-md-6">
                        {customerDetails?.mobileNumber}
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-6">Customer Onboarding Date :</div>
                      <div className="col-md-6">
                        {customerDetails?.onBoardingDate}
                      </div>
                    </div>
                  </div>

                  {/* Ride Profile Details */}
                  <div className="col-md-6">
                    <h5>Ride Profile</h5>
                    <div className="row my-2">
                      <div className="col-md-6">Total Trips Booked :</div>
                      <div className="col-md-6">
                        {customerDetails?.customerRideProfile?.totalTrips}
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-6">Total Completed Trips :</div>
                      <div className="col-md-6">
                        {customerDetails?.customerRideProfile?.completedTrips}
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-6">Total Cancelled Trips :</div>
                      <div className="col-md-6">
                        {customerDetails?.customerRideProfile?.cancelledTrips}
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-6">Lifetime Booking Value :</div>
                      <div className="col-md-6">
                        {customerDetails?.customerRideProfile?.totalTripRevenue}
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-6">Total Refunds :</div>
                      <div className="col-md-6">
                        {customerDetails?.customerRideProfile?.totalRefundAmt}
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-6">Total Waivers :</div>
                      <div className="col-md-6">
                        {customerDetails?.customerRideProfile?.totalWaiverAmt}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <br />
                                    <hr />
                                    <div className='customer-change-logs'>
                                        <h5>Change Logs</h5>
                                        <CustomerLogsTable tripId={'K0C9JXCJ'} />
                                    </div> */}

              {/* SuperSet Integration */}
              <br />
              {/* <div className="customer-change-logs">
                <PanelBar
                  expanded={expanded}
                  onSelect={handleSelect}
                  expandMode={"single"}
                >
                  <PanelBarItem title="Change Logs">
                    <div style={customStyle} className="custom-template">
                      <SupersetPanel
                        dashboardId={
                          process.env.REACT_APP_SUPERSET_CUSTOMER_LOGS_ID
                        }
                        payload={customerLogsPayload}
                      />
                    </div>
                  </PanelBarItem>
                </PanelBar>
              </div> */}
              {/* <div className="customer-change-logs">
                <PanelBar
                  expanded={expanded}
                  onSelect={handleSelect}
                  expandMode={"single"}
                >
                  <PanelBarItem title="Blocking Logs">
                    <div style={customStyle} className="custom-template">
                    </div>
                  </PanelBarItem>
                </PanelBar>
              </div> */}
            </>
          )}
        </>
      )}
      {modalOpen && (
        <>
          <CustomerBlockingModel
            onClose={closeBlockingModal}
            handleSubmit={handleCustomerBlocking}
            blockingType={!customerDetails.blocked ? "Block" : "Unblock"}
          />
        </>
      )}
    </div>
  );
};

export default CustomerManagementHome;
