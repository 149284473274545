import React, { useEffect, useRef, useState } from 'react'
import ModalLayout from '../../../../common/ModalLayout'
import LoadingPanel from '../../../../common/LoadingPanel';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { FormDropDownList, FormInput, FormNumericTextBox, FormTextArea } from '../../../../../utils/forms/FormComponents';
import { TripConfig } from '../../../../../config/tripConfig';
import { requiredValidator } from '../../../../../utils/forms/Validators';
import { Button } from '@progress/kendo-react-buttons';
import axiosService from '../../../../../init/axios';
import { APIConfig } from '../../../../../utils/constants/api.constants';
import { checkMatchingRoles, getLabelValueObjectFromEnum, handleOnWheel, showErrorMessage, showToastMessage } from '../../../../../utils/helpers';
import { useSelector } from 'react-redux';
import { userPermissions } from '../../../../../config/userPermissionConfig';

const AddOrUpdateRefund = ({selectedRefundDetails,tripId,refundStatusUpdate,onClose}) => {
    const [loading,setLoading] = useState(false);
    const numericTextBoxRef = useRef();
    const [refundDetails, setRefundDetails] = useState({});
    const [selectedRefundReason,setSelectedRefundReason] = useState({
        label:'Driver Issue',
        value:'DRIVER_ISSUE'
    });

    const [selectedRefundSubReason,setSelectedRefundSubReason] = useState({
        label:'Driver Drunk',
        value:'DRUNK',
        tag:'DRIVER_INFRACTION'
    });
    const userRoles = useSelector(state => state.auth?.userRoles);

    //To prevent default behaviour of mouseWheel down of Numeric Text Box
    useEffect(()=>{
        const ref = numericTextBoxRef?.current;
        ref?.element?.addEventListener('wheel', handleOnWheel);

        return () => {
            ref?.element?.removeEventListener('wheel', handleOnWheel);
        }
    });

    useEffect(()=>{
        if(selectedRefundDetails){
            const refundStatusString = !checkMatchingRoles(userRoles,userPermissions.refundModification)?'refundStatusNonFinanceUser':'refundStatusFinanceUser';
            const details = {...selectedRefundDetails,refundReason:selectedRefundDetails?.reason,refundSubReason:selectedRefundDetails?.subReason,[refundStatusString]:selectedRefundDetails?.status}
            const updatedDetails = getLabelValueObjectFromEnum(['refundReason','refundSubReason',refundStatusString],details,'trip');
            setRefundDetails({...updatedDetails,refundStatus:updatedDetails[refundStatusString]});
        }

        return ()=>setRefundDetails({});
    },[])

    const onSelectedRefundReasonChange = (e,formRenderProps) => {
        setSelectedRefundReason(e.target.value);
        formRenderProps.onChange("refundSubReason",{value:TripConfig.refundSubReason.filter(item => item.reason === e.target.value?.value)[0]})
        setSelectedRefundSubReason(TripConfig.refundSubReason.filter(item => item.reason === e.target.value?.value)[0]);
    }

    const handleSubmit = async (dataItem) => {
        setLoading(true);
        const formValues = {
            amount: dataItem?.amount,
            remarks: dataItem?.remarks,
            reason: dataItem?.refundReason?.value,
            subReason: dataItem?.refundSubReason?.value,
            status: dataItem?.refundStatus?.value,
            transactionId:dataItem?.transactionId,
            infractionType: dataItem?.refundSubReason?.tag
        }
        if(refundStatusUpdate){
            formValues['id'] = dataItem?.id;
        }
        const url = refundStatusUpdate ? APIConfig.tripManagement.updateRefund(tripId,dataItem?.id) : APIConfig.tripManagement.createRefund(tripId);
        await axiosService[refundStatusUpdate ? 'put' : 'post'](process.env.REACT_APP_TRIP_BASE_URL + url, JSON.stringify(formValues),{ 'Content-Type': 'application/json' })
            .then(data => {
                setLoading(false);
                showToastMessage(refundStatusUpdate ? 'Refund updated successfully' : 'Refund created successfully');
                onClose();
            })
            .catch(error => {
                setLoading(false);
                showErrorMessage(error);
                setRefundDetails(dataItem);
            })
    }

  return (
    <ModalLayout title={'Add Refund'} onClose={onClose} >
        <div className='refund-form-div' style={{minWidth:700}}>
            {
                loading ? <LoadingPanel /> : (
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={refundDetails}
                        key={JSON.stringify(refundDetails)}
                        render={(formRenderProps) => (
                            <FormElement
                                style={{
                                    width: '100%',
                                    // padding: '0px 16px 0px 16px',
                                    // border: '1px solid rgba(0, 0, 0, 0.08)',
                                }}
                            >
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Refund Amount:
                                            </div>
                                            <div className='col-lg-6'>
                                                <Field
                                                    id={"amount"}
                                                    name={"amount"}
                                                    value={refundDetails?.amount}
                                                    format={"n0"}
                                                    component={FormNumericTextBox}
                                                    min={1}
                                                    validator={requiredValidator}
                                                    refP={el => numericTextBoxRef.current=el}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Refund Status:
                                            </div>
                                            <div className='col-lg-6'>
                                                <Field
                                                    id={"refundStatus"}
                                                    name={"refundStatus"}
                                                    component={FormDropDownList}
                                                    data={!checkMatchingRoles(userRoles,userPermissions.refundModification) ? TripConfig.refundStatusNonFinanceUser : TripConfig.refundStatusFinanceUser}
                                                    textField="label"
                                                    dataItemKey="value"
                                                    // defaultValue={TripConfig.refundStatus[0]}
                                                    validator={requiredValidator}
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                Remarks:
                                            </div>
                                            <div className='col-lg-8'>
                                                <Field
                                                    id={"remarks"}
                                                    name={"remarks"}
                                                    value={refundDetails?.remarks}
                                                    component={FormTextArea}
                                                    rows={3}
                                                    validator={requiredValidator}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Refund Reason:
                                            </div>
                                            <div className='col-lg-6'>
                                                <Field
                                                    id={"refundReason"}
                                                    name={"refundReason"}
                                                    component={FormDropDownList}
                                                    data={TripConfig.refundReason}
                                                    textField="label"
                                                    dataItemKey="value"
                                                    // defaultValue={TripConfig.refundReason[0]}
                                                    onChange={(e)=>onSelectedRefundReasonChange(e,formRenderProps)}
                                                    validator={requiredValidator}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Refund Sub Reason:
                                            </div>
                                            <div className='col-lg-6'>
                                                <Field
                                                    id={"refundSubReason"}
                                                    name={"refundSubReason"}
                                                    component={FormDropDownList}
                                                    data={TripConfig.refundSubReason.filter(item => item.reason === selectedRefundReason.value)}
                                                    textField="label"
                                                    dataItemKey="value"
                                                    // defaultValue={TripConfig.refundSubReason.filter(item => item.reason === selectedRefundReason.value)[0]}
                                                    validator={requiredValidator}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Transaction Id:
                                            </div>
                                            <div className='col-lg-6'>
                                                <Field
                                                    id={"transactionId"}
                                                    name={"transactionId"}
                                                    component={FormInput}
                                                    value={refundDetails?.transactionId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-12 txtcenter'>
                                        <Button
                                            themeColor={"primary"}
                                            type={"submit"}
                                            disabled={!formRenderProps.valid}
                                            >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    />
                )
            }
        </div>
    </ModalLayout>
  )
}

export default AddOrUpdateRefund;