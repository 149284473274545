import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import DriverOnlineActivityTable from "./DriverOnlineActivityTable";
import {
  convertMinutesToHoursMinutesFormat,
  dateAndTimeToRequiredFormat,
  prefixZeroToValue,
} from "../../../utils/helpers";
import { useNavigate, useSearchParams } from "react-router-dom";

const DriverOnlineActivity = () => {
  const date = new Date();
  const currentDate =
    date.getFullYear() +
    "-" +
    prefixZeroToValue(Number(date.getMonth() + 1)) +
    "-" +
    prefixZeroToValue(date.getDate());
  const [searchParams, setSearchParams] = useSearchParams();
  const [calendarDate, setCalendarDate] = useState(currentDate);
  const [driverStats, setDriverStats] = useState({});
  const navigate = useNavigate();
  const onCalendarValueChange = (value) => {
    const [day, month, year] = dateAndTimeToRequiredFormat(value);
    setCalendarDate([year, month, day].join("-"));
  };

  return (
    <div className="driver-online-activity-div">
      <span
        className="k-icon k-i-arrow-left"
        onClick={() => navigate("/home/driver")}
      ></span>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-9">
          <DriverOnlineActivityTable
            date={calendarDate}
            currentDate={currentDate}
            setDriverStats={setDriverStats}
          />
        </div>
        <div className="col-lg-3">
          <div className="driver-online-activity-details">
            <div className="driver-details-container">
              <img
                src={require("../../../assets/people/user-default-image.jpeg")}
                alt="user avatar"
              />
              <br />
              <div
                className="driver-name"
                style={{ fontSize: "12px", marginTop: "10px", fontWeight: 500 }}
              >
                {searchParams.get("driverName")
                  ? searchParams.get("driverName")
                  : ""}
              </div>
              <br />
              <div className="driver-stats">
                <div>Total Online Time</div>
                <div>
                  {convertMinutesToHoursMinutesFormat(
                    driverStats?.totalOnlineMinutes
                  )}
                </div>
              </div>
            </div>
            <div className="calendar">
              <Calendar
                value={new Date(calendarDate)}
                onChange={onCalendarValueChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverOnlineActivity;
