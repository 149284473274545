import * as React from "react";
import { useLocation, useNavigate, Outlet, Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../slices/authSlice";
import { clearLocalStorage, showErrorMessage } from "../utils/helpers";
import { useDescope } from "@descope/react-sdk";
import logo from "../assets/logo.svg";
// import PathfinderSvg from "../assets/Pathfinder.svg";
export const items = [
  {
    text: "Trip Management",
    selected: false,
    route: [
      "/home/trip",
      "/home/trip/tripId/details",
      "/home/trip/tripId/assign-driver",
    ],
    icon: "k-icon k-i-notification k-i-globe",
  },
  {
    text: "Fleet Management",
    selected: false,
    route: [
      "/home/driver",
      "/home/driver/driverId/profile",
      "/home/driver/driverId/account",
      "/home/driver/driverId/online-activity",
    ],
    icon: "k-icon k-i-notification k-i-globe",
  },
  // {
  //   text: 'Configuration Management',
  //   selected: false,
  //   route: ['/home/configuration'],
  //   icon: 'k-icon k-i-notification k-i-globe'
  // },
  {
    text: "Pricing Logic Management",
    selected: false,
    route: ["/home/pricing"],
    icon: "k-icon k-i-notification k-i-globe",
  },

  {
    text: "Customer Management",
    selected: false,
    route: ["/home/customer-management"],
    icon: "k-icon k-i-notification k-i-globe",
  },
  {
    text: "Driver Payout",
    selected: false,
    route: ["/home/driver/payout"],
    icon: "k-icon k-i-notification k-i-globe",
  },
  {
    text: "Bulk Operations",
    selected: false,
    route: ["/home/operations"],
    icon: "k-icon k-i-notification k-i-globe",
  },
  {
    text: "Grievance Coupon Management",
    selected: false,
    route: ["/home/coupon-grievance"],
    icon: "k-icon k-i-notification k-i-globe",
  },
  {
    text: "Referral Coupon Management",
    selected: false,
    route: ["/home/coupon-referral"],
    icon: "k-icon k-i-notification k-i-globe",
  },
  {
    text: "Subscriptions",
    selected: false,
    route: ["/home/subscriptions", "/home/subscriptions/subId/details"],
    icon: "k-icon k-i-notification k-i-globe",
  },
];

export const DrawerContainer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(true);

  const user = useSelector((state) => state.auth?.userDetails);

  const { logout } = useDescope();

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const onSelect = (e) => {
    navigate(e.route[0]);
  };

  const locationPaths = location.pathname.split("/");

  const setSelectedItem = (pathName) => {
    let currentPathItem;
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (item?.route?.length > 0) {
        const currentPath = item?.route?.find(
          (route) =>
            route?.split("/")[2] === locationPaths[2] &&
            route?.split("/").at(-1) ===
              (locationPaths?.at(-1) !== ""
                ? locationPaths.at(-1)
                : locationPaths.at(-2))
        );
        if (currentPath !== undefined) {
          currentPathItem = item;
          break;
        }
      }
    }
    if (currentPathItem?.text) {
      return currentPathItem.text;
    }
  };

  let finalPath = "";
  locationPaths.forEach((path, index) => {
    if (index > 0) {
      finalPath += "/" + path;
    }
  });
  const selected = setSelectedItem(finalPath);

  const onSignOut = () => {
    logout()
      .then(() => {
        clearLocalStorage();
        dispatch(logOut());
        navigate("/login");
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  return (
    <>
      <main className="tw-flex tw-items-start tw-h-[100dvh] tw-overflow-hidden">
        <Sidebar
          items={items.map((item) => ({
            ...item,
            selected: item.text === selected,
          }))}
          user={user}
          onSignOut={onSignOut}
          onSelect={onSelect}
        />
        {props.children}
        <Outlet />
      </main>
    </>
  );
};

// New Sidebar
const Sidebar = ({ items, user, onSignOut, onSelect }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationPaths = location.pathname.split("/");

  const [selected, setSelected] = React.useState("");

  React.useEffect(() => {
    const setSelectedItem = (pathName) => {
      let currentPathItem;
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (item?.route?.length > 0) {
          const currentPath = item?.route?.find(
            (route) =>
              route?.split("/")[2] === locationPaths[2] &&
              route?.split("/").at(-1) ===
                (locationPaths?.at(-1) !== ""
                  ? locationPaths.at(-1)
                  : locationPaths.at(-2))
          );
          if (currentPath !== undefined) {
            currentPathItem = item;
            break;
          }
        }
      }
      if (currentPathItem?.text) {
        return currentPathItem.text;
      }
    };

    let finalPath = "";
    locationPaths.forEach((path, index) => {
      if (index > 0) {
        finalPath += "/" + path;
      }
    });
    const selectedItem = setSelectedItem(finalPath);
    setSelected(selectedItem);
  }, [location.pathname, items]);

  const handleSelect = (item) => {
    navigate(item.route[0]);
    if (onSelect) onSelect(item);
  };

  return (
    <>
      <aside
        style={{ borderRight: "2px solid #E8E8E8" }}
        className="tw-w-[240px] tw-flex tw-flex-col tw-justify-between tw-bg-[#FAFAFA] tw-h-full tw-relative"
      >
        <div>
          <div
            className="tw-p-5 tw-h-20 tw-flex tw-items-center"
            style={{ borderBottom: "2px solid #E8E8E8" }}
          >
            <Link className="tw-w-1/2" to={"/home/trip"}>
              <img src={logo} alt="Pathfinder" className="tw-w-full" />
            </Link>
          </div>
          <div className="tw-overflow-hidden">
            {items.map((item, index) => (
              <div
                key={index}
                className={`tw-overflow-hidden tw-px-5 tw-text-ellipsis tw-cursor-pointer tw-py-3 ${
                  item.selected
                    ? "tw-bg-primary tw-text-white"
                    : "hover:tw-bg-black/5"
                }`}
                onClick={() => handleSelect(item)}
              >
                {/* <i className={item.icon}></i> */}
                <span title={item.text} className="tw-whitespace-nowrap">
                  {item.text}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div
          className="tw-flex tw-p-5 tw-items-center tw-gap-2"
          style={{ borderTop: "2px solid #E8E8E8" }}
        >
          <img
            src={require("../assets/people/user-avatar.jpg")}
            alt="user avatar"
            className="tw-w-14"
          />
          <div className="tw-w-full tw-overflow-hidden">
            <span
              title={user?.email}
              className="tw-text-sm tw-cursor-default tw-text-ellipsis tw-overflow-hidden tw-block"
            >
              {user?.email}
            </span>
            <Button
              size={"small"}
              className="tw-bg-primary/80 tw-text-white tw-mt-2 tw-border-none hover:tw-bg-primary"
              onClick={onSignOut}
            >
              Log Out
            </Button>
          </div>
        </div>
      </aside>
    </>
  );
};
