import React, { useEffect, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import DriverProfilesTable from "./driver-profiles/DriverProfilesTable";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import DriverProfileForm from "./driver-profile/DriverProfileForm";
import DriverAccount from "./driver-account/DriverAccount";
import DriverOnlineActivity from "./driver-online-activity/DriverOnlineActivity";

const DriverManagementNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { driverId } = useParams();
  useEffect(() => {
    if (driverId && location.pathname.split("/").at(-1) === "profile") {
      setSelected(0);
    }
    if (driverId && location.pathname.split("/").at(-1) === "account") {
      setSelected(1);
    }
    if (driverId && location.pathname.split("/").at(-1) === "online-activity") {
        setSelected(2);
    }
  }, []);
  const handleSelect = (e) => {
    switch (e.selected) {
      case 0:
        navigate(`/home/driver/${driverId}/profile${searchParams.get('driverName')?'?driverName='+searchParams.get('driverName'):''}`);
        break;
      case 1:
        navigate(`/home/driver/${driverId}/account${searchParams.get('driverName')?'?driverName='+searchParams.get('driverName'):''}`)
        break;
      case 2:
        navigate(`/home/driver/${driverId}/online-activity${searchParams.get('driverName')?'?driverName='+searchParams.get('driverName'):''}`);
        break;
      default:
        break;
    }
    setSelected(e.selected);
  };
  return (
    <>
      {selected !== null && (
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title="Profile">
            {driverId && location.pathname.split("/").at(-1) === "profile" ? (
              <DriverProfileForm />
            ) : (
              <DriverProfilesTable />
            )}
          </TabStripTab>
          <TabStripTab title="Account">
            {driverId && location.pathname.split("/").at(-1) === "account" ? (
              <DriverAccount />
            ) : (
              <DriverProfilesTable />
            )}
          </TabStripTab>
          <TabStripTab title="Online Activity">
            {driverId &&
            location.pathname.split("/").at(-1) === "online-activity" ? (
              <DriverOnlineActivity />
            ) : (
              <DriverProfilesTable />
            )}
          </TabStripTab>
        </TabStrip>
      )}
    </>
  );
};

export default DriverManagementNavigation;
