import { useLocation } from "react-router-dom";
export const pathsWithFiltered = [
  "/home/driver",
  "/home/trip",
  "/home/pricing",
  "/home/subscriptions",
];

const CardLayout = ({ title, children, ComponentRender, ...props }) => {
  const { pathname } = useLocation();
  const showFilter = false || pathsWithFiltered.includes(pathname);
  return (
    <>
      <section className="tw-h-full tw-w-full tw-overflow-y-auto">
        <div
          className="tw-px-5 tw-h-20 tw-flex tw-items-center tw-gap-5"
          style={{ borderBottom: "2px solid #E8E8E8" }}
        >
          <span className="tw-text-xl tw-font-medium tw-whitespace-nowrap">
            {title}
          </span>
          {ComponentRender && showFilter ? (
            <div className="tw-w-full">
              <ComponentRender {...props} />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="tw-p-5">{children}</div>
      </section>
    </>
  );
};

export default CardLayout;
