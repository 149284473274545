import { embedDashboard } from '@superset-ui/embedded-sdk';
import React, { useEffect, useRef } from 'react'
import { fetchGuestToken } from '../../services/apache-superset/apache-superset.service';
import { getFromLocalStorage } from '../../utils/helpers';

const SupersetPanel = ({dashboardId,payload}) => {
    const supersetRef = useRef();
    const userDetails = getFromLocalStorage('userDetails');

    useEffect(() => {
      const embedSupersetDashboard = async () => {
        const element = document.getElementById("dashboard-superset");
        if (!element) {
          return;
        }
        supersetRef.current = await embedDashboard({
          id: dashboardId,
          supersetDomain: process.env.REACT_APP_APACHE_SUPERSET_BASE_URL,
          mountPoint: element,
          fetchGuestToken: async () => {
            const response = await fetchGuestToken({
              payload: {
                    ...payload,
                  "user": {
                    "username": userDetails?.email 
                    }
              },
            });
            return response.data?.token;
          },
          dashboardUiConfig: {
            hideChartControls: true,
            hideTitle: true,
            filters: {
              expanded: false,
            }
          },
        });
      };

      embedSupersetDashboard();

      return () => {
        if (supersetRef && supersetRef.current) {
          supersetRef.current.unmount();
        }
      };
    }, []);

    return <div id="dashboard-superset" />;
}

export default SupersetPanel;