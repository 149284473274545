import React from 'react'
import CardLayout from '../../components/common/CardLayout';
import GrievanceCouponHomePage from '../../components/coupon-management/GrievanceCouponHomePage';

const GrievanceCouponManagement = () => {
  return (
    <CardLayout title="ATLAS Grievance Coupon Management">
        <GrievanceCouponHomePage />
    </CardLayout>
  )
}

export default GrievanceCouponManagement;