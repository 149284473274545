import React, { useEffect, useState } from 'react'
import axiosService from '../../../../../init/axios';
import { convertEnumToLabel, dateToDaysHoursMinutesSeconds, replaceNullValues, showErrorMessage } from '../../../../../utils/helpers';
import { APIConfig } from '../../../../../utils/constants/api.constants';
import LoadingPanel from '../../../../common/LoadingPanel';

const Pricing = ({ tripId, journeyMode, tripType, selectedDuration, selectedDurationUnit, actualTripStartTime, actualTripEndTime, couponCode }) => {

  const [pricingDetails, setPricingDetails] = useState();
  const [loading, setLoading] = useState(false)

  //Fetch pricing details from API
  useEffect(() => {
    getTripPricing();
  }, []);

  const getTripPricing = async () => {
    setLoading(true);
    await axiosService.get(process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.getTripPricing(tripId))
      .then(data => {
        let actualPricing=data.data?.actualTripFare,expectedPricing=data.data?.expectedTripFare;
        let expectedTripDuration='',actualTripDuration='';
        if(expectedPricing){
          for(const key in expectedPricing){
            expectedPricing[key] = expectedPricing[key]?expectedPricing[key]:(expectedPricing[key] === 0 ? 0 : '-'); 
            if(key==='discount'){
              expectedPricing[key+'Color'] = expectedPricing[key] === 0 ? {color:'#D2D2D2'} : {color:'#68DF0A'};
            }else{
              expectedPricing[key+'Color'] = expectedPricing[key] === 0 ? {color:'#D2D2D2'} : {color:'#606060'};
            }
          }
        }
        if(actualPricing){
          for(const key in actualPricing){
            actualPricing[key] = actualPricing[key]?actualPricing[key]:(actualPricing[key] === 0 ? 0 : '-'); 
            if(key==='discount'){
              actualPricing[key+'Color'] = actualPricing[key] === 0 ? {color:'#D2D2D2'} : {color:'#68DF0A'};
            }else{
              actualPricing[key+'Color'] = actualPricing[key] === expectedPricing[key] ? (actualPricing[key] === 0 ? {color:'#D2D2D2'} : {color:'#606060'}) : {color:'#AA0606'};
            }
          }
        }

        //Setting Trip Duration
        if(selectedDuration && selectedDurationUnit){
          expectedTripDuration = selectedDuration+' '+selectedDurationUnit;
        }else{
          expectedTripDuration = '';
        }

        if(actualTripStartTime && actualTripEndTime){
          actualTripDuration = dateToDaysHoursMinutesSeconds(actualTripEndTime,actualTripStartTime);
        }else {
          actualTripDuration = '';
        }

        setPricingDetails({actualTripFare:{...actualPricing},expectedTripFare:{...expectedPricing},expectedTripDuration,actualTripDuration});
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        showErrorMessage(error);
      })
  }

  return (
    <>
      {
        loading ? <LoadingPanel /> : (
          <div className='pricing-details'>
            {/* <p>Journey Mode : <b>{journeyMode && journeyMode !== null ? convertEnumToLabel(journeyMode) : replaceNullValues(journeyMode)}</b></p> */}
            {/* <p>Booked Duration : <b>{replaceNullValues(pricingDetails?.bookedDuration)}</b></p> */}
            <div className='row'>
              <div className='col-sm-7'>
                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    {/* <b>Priced Header</b> */}
                  </div>
                  <div className='col-sm-3'>
                    <b><u>At Booking Creation</u></b>
                  </div>
                  <div className='col-sm-3'>
                    <b><u>At Booking Completion</u></b>
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    TRIP TYPE
                  </div>
                  <div className='col-sm-3 grey-background' style={{color:'#606060'}}>
                    {convertEnumToLabel(tripType)}
                  </div>
                  <div className='col-sm-3 grey-background' style={{color:'#606060'}}>
                    {convertEnumToLabel(tripType)}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    TRIP SUBTYPE
                  </div>
                  <div className='col-sm-3 grey-background' style={{color:'#606060'}}>
                    {convertEnumToLabel(journeyMode)}
                  </div>
                  <div className='col-sm-3 grey-background' style={{color:'#606060'}}>
                    {convertEnumToLabel(journeyMode)}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    TRIP DURATION
                  </div>
                  <div className='col-sm-3 grey-background' style={{color:'#606060'}}>
                    {pricingDetails?.expectedTripDuration}
                  </div>
                  <div className='col-sm-3 grey-background' style={{color:'#606060'}}>
                    {pricingDetails?.actualTripDuration}
                  </div>
                </div>


                <br />

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    BASE RATE
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.baseRateColor && pricingDetails?.expectedTripFare?.baseRateColor}>
                    {pricingDetails?.expectedTripFare?.baseRate}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.baseRateColor && pricingDetails?.actualTripFare?.baseRateColor}>
                    {pricingDetails?.actualTripFare?.baseRate}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    ONE WAY CHARGE
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.oneWayChargesColor}>
                    {pricingDetails?.expectedTripFare?.oneWayCharges}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.oneWayChargesColor}>
                    {pricingDetails?.actualTripFare?.oneWayCharges}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    WEEKEND CHARGE
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.weekendChargesColor}>
                    {pricingDetails?.expectedTripFare?.weekendCharges}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.weekendChargesColor}>
                    {pricingDetails?.actualTripFare?.weekendCharges}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    NIGHT CHARGE
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.nightChargesColor}>
                    {pricingDetails?.expectedTripFare?.nightCharges}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.nightChargesColor}>
                    {pricingDetails?.actualTripFare?.nightCharges}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    OUTSTATION CHARGE
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.outstationChargesColor}>
                    {pricingDetails?.expectedTripFare?.outstationCharges}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.outstationChargesColor}> 
                    {pricingDetails?.actualTripFare?.outstationCharges}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    PLATFORM FEES
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.platformFeesColor}>
                    {pricingDetails?.expectedTripFare?.platformFees}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.platformFeesColor}>
                    {pricingDetails?.actualTripFare?.platformFees}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    TIP
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.tipColor}>
                    {pricingDetails?.expectedTripFare?.tip}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.tipColor}>
                    {pricingDetails?.actualTripFare?.tip}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    INSURANCE
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.insuranceChargesColor}>
                    {pricingDetails?.expectedTripFare?.insuranceCharges}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.insuranceChargesColor}>
                    {pricingDetails?.actualTripFare?.insuranceCharges}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    TOTAL BEFORE DISCOUNT
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.totalChargesColor}>
                    {pricingDetails?.expectedTripFare?.totalCharges}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.totalChargesColor}>
                    {pricingDetails?.actualTripFare?.totalCharges}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    DISCOUNT {couponCode && <span style={{color:'#68DF0A'}}>({couponCode})</span>}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.discountColor}>
                    {pricingDetails?.expectedTripFare?.discount}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.discountColor}>
                    {pricingDetails?.actualTripFare?.discount}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    WAIVER
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.waiverColor}>
                    {pricingDetails?.expectedTripFare?.waiver}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.waiverColor}>
                    {pricingDetails?.actualTripFare?.waiver}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    GST
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.gstColor}>
                    {pricingDetails?.expectedTripFare?.gst}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.gstColor}>
                    {pricingDetails?.actualTripFare?.gst}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4'>
                    ROUNDING
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.expectedTripFare?.roundingColor}>
                    {pricingDetails?.expectedTripFare?.rounding}
                  </div>
                  <div className='col-sm-3 grey-background' style={pricingDetails?.actualTripFare?.roundingColor}>
                    {pricingDetails?.actualTripFare?.rounding}
                  </div>
                </div>

                <div className='row text-align-center'>
                  <div className='col-sm-4' style={{fontSize:'20px'}}>
                    FINAL FARE
                  </div>
                  <div className='col-sm-3 grey-background' style={{...pricingDetails?.expectedTripFare?.roundedCustomerCollectAmountColor,fontSize:'20px'}}>
                    {pricingDetails?.expectedTripFare?.roundedCustomerCollectAmount}
                  </div>
                  <div className='col-sm-3 grey-background' style={{...pricingDetails?.actualTripFare?.roundedCustomerCollectAmountColor,fontSize:'20px'}}>
                    {pricingDetails?.actualTripFare?.roundedCustomerCollectAmount}
                  </div>
                </div>

              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Pricing