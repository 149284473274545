import React, { useEffect } from "react";
import Routing from "./Routing.js";
import { useDispatch, useSelector } from "react-redux";
import {
  logIn,
  logOut,
  setUserDetails,
  setUserRoles,
} from "./slices/authSlice.js";
import {
  clearLocalStorage,
  getFromLocalStorage,
  showErrorMessage,
} from "./utils/helpers.js";
import { useSession } from "@descope/react-sdk";
import { updateRegions, updateRegionFilter } from "./slices/regionSlice.js";
import axiosService from "./init/axios.js";
import { APIConfig } from "./utils/constants/api.constants.js";

const App = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, isSessionLoading } = useSession();
  const regions = useSelector((state) => state.region?.regions);
  useEffect(() => {
    if (getFromLocalStorage("userLoggedIn")) {
      if (isAuthenticated && !isSessionLoading) {
        dispatch(setUserDetails(getFromLocalStorage("userDetails")));
        dispatch(
          setUserRoles(
            getFromLocalStorage("userDetails")?.userTenants.filter(
              (tenant) =>
                tenant?.tenantId === process.env.REACT_APP_DESCOPE_TENANT_ID
            )[0]?.roleNames
          )
        );
        dispatch(logIn());
      }
    } else {
      clearLocalStorage();
      dispatch(logOut());
    }
    if (regions?.length === 0) {
      getRegions();
    }
  }, [isAuthenticated, isSessionLoading]);

  const getRegions = async () => {
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getRegions +
          "?key=REGION_CONFIG"
      )
      .then((data) => {
        const regions = data?.data?.regions?.map((data) => ({
          ...data,
          label: data?.regionName,
          value: data?.regionId,
        }));
        const subRegions = data?.data?.subRegions?.map((data) => ({
          ...data,
          label: data?.regionName,
          value: data?.regionId,
        }));
        const allRegions = {
          subRegions: subRegions,
          regions: regions,
        };
        dispatch(updateRegions(allRegions));
        if (getFromLocalStorage("regionFilters")) {
          dispatch(updateRegionFilter(getFromLocalStorage("regionFilters")));
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  return (
    <div className="App">
      <Routing />
    </div>
  );
};

export default App;
