
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const accountNumberRegex = new RegExp(/^[0-9]{9,18}$/);
const ifscCodeRegex = new RegExp(/^[A-Za-z]{4}0[a-zA-Z0-9]{6}$/);

export const termsValidator = value => value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = value => !value ? "Email field is required." : emailRegex.test(value) ? "" : "Email is not in a valid format.";
export const nameValidator = value => !value ? "Name is required" : "";
export const userNameValidator = value => !value ? "User Name is required" : value.length < 5 ? "User name should be at least 5 characters long." : "";
export const phoneValidator = value => !value ? "Phone number is required." : phoneRegex.test(value) ? "" : "Not a valid phone number.";
export const dateValidator = value => value ? "" : "Date is required.";
export const colorValidator = value => value ? "" : "Color is required.";
export const requiredValidator = value => value ? "" : "Error: This field is required.";
export const requiredArrayValidator = value => value && value.length>0 ? "" : "This field is required.";
export const passwordValidator = value => value && value.length >= 6 ? '' : 'Password must be at least 6 characters long.';
export const addressValidator = value => value ? "" : "Address is required.";
export const bankAccountNumberValidator = value => !value ? "Bank Account Number is required." : accountNumberRegex.test(value) ? "" : "Not a valid bank account number.";
export const ifscCodeValidator = value => !value ? "IFSC Code is required." : ifscCodeRegex.test(value) ? "" : "Not a valid ifsc code.";