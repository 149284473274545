import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CategoryFilterCell,
  CategoryFilterMultiSelectCell,
  getLabelFromEnum,
  stringToSentenceCase,
} from "../../utils/helpers";
import GridLoader from "../common/GridLoader";
import { APIConfig } from "../../utils/constants/api.constants";
import { TripConfig } from "../../config/tripConfig";
import { useSelector } from "react-redux";

const TripManagementTable = ({
  setTripDetailsSelected,
  dataState,
  setDataState,
}) => {
  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.getAllTrips;
  const navigate = useNavigate();
  const selectedTripRegionFilters = useSelector((state) => state.region?.trip);
  const [trips, setTrips] = useState({
    data: [],
    total: 0,
  });

  const columns = [
    {
      id: "tripId",
      field: "tripId",
      title: "Trip ID",
      filterable: true,
      filterKey: "trip_id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 100,
    },
    {
      id: "customerName",
      field: "customerName",
      title: "Customer Name",
      filterable: true,
      filterKey: "customer_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 180,
    },
    {
      id: "customerMobile",
      field: "customerMobile",
      title: "Customer Mobile",
      filterable: true,
      filterKey: "customer_mobile",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 150,
    },
    {
      id: "derivedTripType",
      field: "derivedTripType",
      title: "Trip Type",
      filterable: true,
      filterKey: "derived_trip_type",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: TripConfig.derivedTripType,
      width: 130,
    },
    {
      id: "journeyMode",
      field: "journeyMode",
      title: "Journey Mode",
      filterable: true,
      filterKey: "journey_mode",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: TripConfig.journeyMode,
      width: 130,
    },
    {
      id: "tripBookingMode",
      field: "tripBookingMode",
      title: "Trip Booking Mode",
      filterable: true,
      filterKey: "trip_booking_mode",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: TripConfig.tripBookingMode,
      width: 150,
    },
    {
      id: "stage",
      field: "stage",
      title: "Stage",
      filterable: true,
      filterKey: "stage",
      filterType: "string",
      defaultFilterOperator: "eqIn",
      data: TripConfig.stage,
      width: 220,
    },
    {
      id: "tag",
      field: "tag",
      title: "Tag",
      filterable: true,
      filterKey: "tag",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: TripConfig.tag,
      width: 130,
    },
    {
      id: "driverId",
      field: "driverId",
      title: "Driver Id",
      filterable: true,
      filterKey: "driver_id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 100,
    },
    {
      id: "fullName",
      field: "fullName",
      title: "Driver Name",
      filterable: false,
      filterKey: "full_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 180,
    },
    {
      id: "driverMobile",
      field: "driverMobile",
      title: "Driver Mobile",
      filterable: false,
      filterKey: "driver_mobile",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 180,
    },
    {
      id: "regionId",
      field: "regionId",
      title: "Region Id",
      filterable: false,
      width: 180,
    },
    {
      id: "tripCreationTime",
      field: "tripCreationTime",
      title: "Trip Creation Time",
      filterable: false,
      width: 200,
    },
    {
      id: "expectedTripStartTime",
      field: "expectedTripStartTime",
      title: "Expected Trip Start Time",
      filterable: false,
      width: 200,
    },
    {
      id: "actualTripStartTime",
      field: "actualTripStartTime",
      title: "Actual Trip Start Time",
      filterable: false,
      width: 200,
    },
    {
      id: "expectedTripEndTime",
      field: "expectedTripEndTime",
      title: "Expected Trip End Time",
      filterable: false,
      width: 200,
    },
    {
      id: "actualTripEndTime",
      field: "actualTripEndTime",
      title: "Actual Trip End Time",
      filterable: false,
      width: 200,
    },
    {
      id: "pickLocation",
      field: "pickLocation",
      title: "Pick Location",
      filterable: false,
      width: 700,
    },
    {
      id: "dropLocation",
      field: "dropLocation",
      title: "Drop Location",
      filterable: false,
      width: 700,
    },
  ];

  // useEffect(() => {
  //     //Get Page if it's there
  //     if (searchParams.get('pageNumber')) {
  //         const skip = (searchParams.get('pageNumber') - 1) * dataState.take;
  //         setDataState({ ...dataState, skip: skip });
  //     }
  // }, []);

  const dataStateChange = (e) => {
    if (selectedTripRegionFilters?.length > 0) {
      const regionQueryStr = selectedTripRegionFilters.reduce(
        (acc, curr) => (acc += curr?.value + ","),
        ""
      );
      setDataState({
        ...e.dataState,
        otherParams: {
          parent_region_id: regionQueryStr.slice(0, -1) + ":in:string",
        },
      });
    } else {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0
      ) {
        setDataState({
          ...e.dataState,
          otherParams: {},
        });
      } else {
        setDataState(e.dataState);
      }
    }
  };
  const dataReceived = (dataObj) => {
    const arr = dataObj.data.trips.map((trip) => {
      //Getting labels from enum
      const updatedTripDetails = getLabelFromEnum(
        [
          "derivedTripType",
          "journeyMode",
          "scheduled",
          "stage",
          "tripBookingMode",
          "tag",
        ],
        trip,
        "trip"
      );
      let tripObj = {};

      if (trip?.customerName) {
        tripObj = {
          ...tripObj,
          customerName: stringToSentenceCase(trip?.customerName),
        };
      } else {
        tripObj = { ...tripObj, customerName: "" };
      }

      if (trip?.customerMobile) {
        tripObj = { ...tripObj, customerMobile: trip?.customerMobile };
      } else {
        tripObj = { ...tripObj, customerMobile: "" };
      }

      if (trip?.tripBookingMode) {
        tripObj = { ...tripObj, tripBookingMode: trip?.tripBookingMode };
      } else {
        tripObj = { ...tripObj, tripBookingMode: "" };
      }

      if (trip?.tripCreationTime) {
        tripObj = { ...tripObj, tripCreationTime: trip?.tripCreationTime };
      } else {
        tripObj = { ...tripObj, tripCreationTime: "" };
      }

      if (trip?.actualSourceLocation?.heading) {
        tripObj = {
          ...tripObj,
          pickLocation: trip?.actualSourceLocation?.heading,
        };
      } else if (trip?.expectedSourceLocation?.heading) {
        tripObj = {
          ...tripObj,
          pickLocation: trip?.expectedSourceLocation?.heading,
        };
      } else {
        tripObj = { ...tripObj, pickLocation: "" };
      }

      if (trip?.actualDestinationLocation?.heading) {
        tripObj = {
          ...tripObj,
          dropLocation: trip?.actualDestinationLocation?.heading,
        };
      } else if (trip?.expectedSourceLocation?.heading) {
        tripObj = {
          ...tripObj,
          dropLocation: trip?.expectedDestinationLocation?.heading,
        };
      } else {
        tripObj = { ...tripObj, dropLocation: "" };
      }

      if (trip?.driverDetails?.fullName) {
        tripObj = {
          ...tripObj,
          fullName: stringToSentenceCase(trip?.driverDetails?.fullName),
        };
      } else {
        tripObj = { ...tripObj, fullName: "" };
      }

      if (trip?.driverMobile) {
        tripObj = {
          ...tripObj,
          driverMobile: stringToSentenceCase(trip?.driverMobile),
        };
      } else {
        tripObj = { ...tripObj, driverMobile: "" };
      }

      return { ...updatedTripDetails, ...tripObj };
    });
    setTrips({ ...dataObj, data: arr });
  };
  const onCustomerRowClick = (item) => {
    setTripDetailsSelected(true);
    // let queryStr = '?pageNumber=' + ((dataState.skip / dataState.take) + 1);
    navigate("/home/trip/" + item?.dataItem?.tripId + "/details");
  };
  return (
    <div className="trip-management-table">
      <Grid
        filterable={true}
        sortable={false}
        pageable={true}
        {...dataState}
        data={trips}
        onDataStateChange={dataStateChange}
        onRowDoubleClick={onCustomerRowClick}
      >
        {columns?.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
            filterable={column?.filterable}
            width={column?.width}
            filterCell={
              (column.defaultFilterOperator === "eq" &&
                ((props) => CategoryFilterCell(props, column))) ||
              (column.defaultFilterOperator === "eqIn" &&
                ((props) => CategoryFilterMultiSelectCell(props, column)))
            }
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
    </div>
  );
};

export default TripManagementTable;
