import React, { useEffect, useState } from "react";
import { APIConfig } from "../../../utils/constants/api.constants";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import GridLoader from "../../common/GridLoader";
import { useNavigate, useParams } from "react-router-dom";
import { convertMinutesToHoursMinutesFormat } from "../../../utils/helpers";
import { Button } from "@progress/kendo-react-buttons";
import axiosService from "../../../init/axios";
import { leftArrowName } from "@progress/kendo-react-layout";
import DriverUniformAdherenceImage from "./DriverUniformAdherenceImage";

const DriverOnlineActivityTable = ({ date, currentDate, setDriverStats }) => {
  const { driverId } = useParams();
  const baseUrl =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.driverManagement.getDriverOnlineActivityList(driverId);
  //   const baseUrl = '';
  const [driverOnlineList, setDriverOnlineList] = useState({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = React.useState({
    take: 10,
    skip: 0,
    otherParams: {
      date,
    },
  });
  const [showImage, setShowImage] = useState({ show: false, imgUrl: "" });
  const [dateChangedOnce, setDateChangedOnce] = useState(false);

  const columns = [
    {
      id: "onlineTime",
      field: "onlineTime",
      title: "Online Time",
      filterable: false,
      width: 100,
    },
    {
      id: "onlineLocation",
      field: "onlineLocation",
      title: "Location",
      filterable: false,
      width: 325,
    },
    {
      id: "offlineTime",
      field: "offlineTime",
      title: "Offline Time",
      filterable: false,
      width: 100,
    },
    {
      id: "offlineLocation",
      field: "offlineLocation",
      title: "Location",
      filterable: false,
      width: 325,
    },
    {
      id: "netDuration",
      field: "netDuration",
      title: "Net online duration",
      filterable: false,
      width: 275,
    },
    {
      id: "uniformAdherenceImage",
      field: "uniformAdherenceImage",
      title: "Uniform Adherence Image",
      filterable: false,
      width: 275,
      cell: (props) => (
        <ViewUniformImage
          {...props}
          setShowImage={setShowImage}
          showImage={showImage}
        />
      ),
    },
    {
      id: "uniformAdherenceStatus",
      field: "uniformAdherenceStatus",
      title: "Uniform Adherence Status",
      filterable: false,
      width: 200,
    },
  ];

  useEffect(() => {
    if (String(date) !== String(currentDate)) {
      setDataState({ ...dataState, otherParams: { date } });
      if (!dateChangedOnce) {
        setDateChangedOnce(true);
      }
    }
    if (String(date) === String(currentDate) && dateChangedOnce) {
      setDataState({ ...dataState, otherParams: { date } });
    }
  }, [date]);

  const dataStateChange = (e) => {
    setDataState({ ...e.dataState, otherParams: { date } });
  };

  const dataReceived = (dataObj) => {
    setDriverStats(dataObj?.data);
    if (dataObj.data?.onlineStats) {
      const arr = dataObj.data.onlineStats.map((stats) => {
        let onlineTime = "",
          offlineTime = "",
          onlineLocation = "",
          offlineLocation = "",
          netDuration = "",
          uniformAdherenceImage = "",
          uniformAdherenceStatus = "";
        if (stats?.startTime) {
          onlineTime = stats?.startTime.split("T")[1].split(".")[0];
        } else {
          onlineTime = "N.A";
        }
        if (stats?.endTime) {
          offlineTime = stats?.endTime.split("T")[1].split(".")[0];
        } else {
          offlineTime = "N.A";
        }

        if (stats?.startLatitude && stats?.startLongitude) {
          onlineLocation = stats?.startLatitude + ", " + stats?.startLongitude;
        } else {
          onlineLocation = "N.A";
        }
        if (stats?.endLatitude && stats?.endLongitude) {
          offlineLocation = stats?.endLatitude + ", " + stats?.endLongitude;
        } else {
          offlineLocation = "N.A";
        }

        if (stats?.startTime && stats?.endTime) {
          netDuration = convertMinutesToHoursMinutesFormat(
            stats?.durationMinutes
          );
        } else {
          netDuration = "N.A";
        }
        if (stats?.imageUrl) {
          uniformAdherenceImage = stats.imageUrl; // show image in table
        }
        uniformAdherenceStatus =
          stats.wearingUniform === true
            ? "Passed"
            : stats.wearingUniform === false
            ? "Failed"
            : "N.A";
        const updatedDetails = {
          ...stats,
          onlineTime,
          offlineTime,
          onlineLocation,
          offlineLocation,
          netDuration,
          uniformAdherenceImage,
          uniformAdherenceStatus,
        };
        return updatedDetails;
      });
      setDriverOnlineList({ ...dataObj, data: arr });
    } else {
      setDriverOnlineList({ ...dataObj, data: [] });
    }
  };

  return (
    <>
      <div className="driver-online-table">
        <Grid
          filterable={false}
          sortable={false}
          pageable={false}
          {...dataState}
          // data={process(driverOnlineListData, dataState)}
          data={driverOnlineList}
          onDataStateChange={dataStateChange}
        >
          {columns.map((column) => (
            <Column
              key={column?.id}
              field={column?.field}
              title={column?.title}
              filterable={column?.filterable}
              width={column?.width}
              cell={column?.cell || ""}
            />
          ))}
        </Grid>

        <GridLoader
          baseUrl={baseUrl}
          columns={columns}
          dataState={dataState}
          onDataReceived={dataReceived}
        />
      </div>
      {showImage.show && (
        <DriverUniformAdherenceImage
          setShowImage={setShowImage}
          basePath={showImage.imgUrl}
        />
      )}
    </>
  );
};

export default DriverOnlineActivityTable;

const ViewUniformImage = (props) => {
  const { setShowImage, dataItem } = props;
  let imgUrl = dataItem.uniformAdherenceImage;

  if (!imgUrl)
    return (
      <td
        colSpan="1"
        className=""
        role="gridcell"
        aria-colindex="5"
        aria-selected="false"
        data-grid-col-index="5"
      >
        N.A
      </td>
    );
  return (
    <>
      <td
        colSpan="1"
        className=""
        role="gridcell"
        aria-colindex="5"
        aria-selected="false"
        data-grid-col-index="5"
      >
        <Button
          themeColor={"secondary"}
          onClick={() =>
            setShowImage({
              show: true,
              imgUrl: imgUrl,
            })
          }
        >
          View More
        </Button>
      </td>
    </>
  );
};
