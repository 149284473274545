export const DriverConfig = {
    bgvStatus : [
        {
            label:'Initiated',
            value:'INITIATED',
        },
        {
            label:'Success',
            value:'SUCCESS',
        },
        {
            label:'Not Started',
            value:'NOT_STARTED',
        },
        {
            label:'Failed',
            value:'FAILED',
        },
    ],

    testDriveStatus : [
        {
            label:'Initiated',
            value:'INITIATED',
        },
        {
            label:'Success',
            value:'SUCCESS',
        },
        {
            label:'Not Started',
            value:'NOT_STARTED',
        },
        {
            label:'Failed',
            value:'FAILED',
        },
    ],

    transmissionTypePreferences : [
        {
            label:'Automatic',
            value:'AUTOMATIC'
        },
        {
            label:'Manual',
            value:'MANUAL'
        }
    ],

    carTypePreferences : [
        {
            label:'Hatchback',
            value:'HATCHBACK',
        },
        {
            label:'Sedan',
            value:'SEDAN',
        },
        {
            label:'SUV',
            value:'SUV',
        },
        {
            label:'Luxury',
            value:'LUXURY',
        }
    ],

    tripTypePreferences : [
        {
            label:'In City',
            value:'IN_CITY',
        },
        {
            label:'Outstation',
            value:'OUTSTATION',
        },
    ],

    driverPayoutType : [
        {
            label:'Variable',
            value:'VARIABLE'
        },
        {
            label:'Fixed',
            value:'FIXED'
        }
    ],

    onboardingStage : [
        {
            label:'Not Started',
            value:'NOT_STARTED'
        },
        {
            label:'Bank Verification',
            value:'BANK_VERIFICATION'
        },
        {
            label:'Onboarding form complete',
            value:'ONBOARDING_FORM_COMPLETE'
        },
        {
            label:'Document upload',
            value:'DOCUMENT_UPLOAD'
        },
        {
            label:'Preferences',
            value:'PREFERENCES'
        },
        {
            label:'Personal Details',
            value:'PERSONAL_DETAILS'
        },
        {
            label:'Profile Photo',
            value:'PROFILE_PHOTO'
        }
    ],

    blocked : [
        {
            label:'Active',
            value:false
        },
        {
            label:'Blocked',
            value:true
        }
    ],

    driverStatus : [
        {
            label:'Online',
            value:'ONLINE'
        },
        {
            label:'Out of serviceable area',
            value:'OUT_OF_SERVICEABLE_AREA'
        },
        {
            label:'Trip acceptance timer running',
            value:'TRIP_ACCEPTANCE_TIMER_RUNNING'
        },
        {
            label:'In Trip',
            value:'IN_TRIP'
        },
        {
            label:'Offline',
            value:'OFFLINE'
        }
    ],

    driverAlerts:[
        {
			"type": "BANKING_ALERT",
			"code": "PAYOUT_ERROR",
            "label": "accountNumberAlert"
		}
    ],

    languages:[
        {
            label: "English",
            value: "ENGLISH"
        },
        {
            label: "Hindi",
            value: "HINDI"
        },
        {
            label: "Kannada",
            value: "KANNADA"
        },
        {
            label: "Telugu",
            value: "TELUGU"
        },
        {
            label: "Punjabi",
            value: "PUNJABI"
        },
        {
            label: "Tamil",
            value: "TAMIL"
        },
        {
            label: "Odia",
            value: "ODIA"
        },
        {
            label: "Marathi",
            value: "MARATHI"
        },
        {
            label: "Malayalam",
            value: "MALAYALAM"
        }
    ]
}