import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name:'auth',
    initialState:{
        userLoggedIn:false,
        userDetails:null,
        token: null,
        userRoles:null
    },
    reducers:{
        logIn : (state) => {
            state.userLoggedIn = true;
        },
        logOut : (state) => {
            state.userLoggedIn = false;
            state.userDetails = null;
            state.userRoles = null;
            state.token = null;
        },
        setUserDetails : (state,action) => {
            state.userLoggedIn = true;
            state.userDetails = action.payload;
        },
        setToken : (state,action) => {
            state.token = action.payload;
        },
        setUserRoles : (state,action) => {
            state.userRoles = action.payload
        }
    }
})

export const {logIn,logOut,setUserDetails,setToken,setUserRoles} = authSlice.actions;
export default authSlice.reducer;

