import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { useNavigate } from "react-router-dom";
import {
  CategoryFilterCell,
  getLabelFromEnum,
  initialFilter,
  stringToSentenceCase,
} from "../../../utils/helpers";
import GridLoader from "../../common/GridLoader";
import { APIConfig } from "../../../utils/constants/api.constants";
import { DriverConfig } from "../../../config/driverConfig";
import { useSelector } from "react-redux";

const DriverProfilesTable = ({ dataState, setDataState }) => {
  const baseUrl =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.driverManagement.getAllDrivers;
  const navigate = useNavigate();
  
  const selectedDriverRegionFilters = useSelector(
    (state) => state.region?.driver
  );

  const [driverProfiles, setDriverProfiles] = useState({
    data: [],
    total: 0,
  });

  const columns = [
    {
      id: "driverId",
      field: "driverId",
      title: "Driver ID",
      filterable: true,
      filterKey: "driver_id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "fullName",
      field: "fullName",
      title: "Driver Name",
      filterable: true,
      filterKey: "full_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 210,
    },
    {
      id: "onboardingStage",
      field: "onboardingStage",
      title: "Onboarding Status",
      filterable: true,
      filterKey: "onboarding_stage",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: DriverConfig.onboardingStage,
      width: 220,
    },
    {
      id: "mobileNumber",
      field: "mobileNumber",
      title: "Mobile Number",
      filterable: true,
      filterKey: "mobile_number",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "bgvStatus",
      field: "bgvStatus",
      title: "3rd Paty BGV",
      filterable: true,
      filterKey: "bgv_status",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: DriverConfig.bgvStatus,
      width: 200,
    },
    {
      id: "testDriveStatus",
      field: "testDriveStatus",
      title: "Driving Test Status",
      filterable: true,
      filterKey: "test_drive_status",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: DriverConfig.testDriveStatus,
      width: 200,
    },
    {
      id: "driverPayoutType",
      field: "driverPayoutType",
      title: "Earnings Plan",
      filterable: true,
      filterKey: "driver_payout_type",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: DriverConfig.driverPayoutType,
      width: 200,
    },
    {
      id: "regionId",
      field: "regionId",
      title: "Region Id",
      filterable: false,
      width: 200,
    },
    {
      id: "driverStatus",
      field: "driverStatus",
      title: "Current Status",
      filterable: true,
      filterKey: "driver_status",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: DriverConfig.driverStatus,
      width: 200,
    },
    {
      id: "lastLocation",
      field: "lastLocation",
      title: "Last Location",
      filterable: false,
      width: 300,
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
      filterable: false,
      width: 200,
    },
  ];

  // useEffect(() => {
  //     //Get Page if it's there
  //     if (searchParams.get('pageNumber')) {
  //         const skip = (searchParams.get('pageNumber') - 1) * dataState.take;
  //         setDataState({ ...dataState, skip: skip });
  //     }
  // }, []);

  const dataStateChange = (e) => {
    if (selectedDriverRegionFilters?.length > 0) {
      const regionQueryStr = selectedDriverRegionFilters.reduce(
        (acc, curr) => (acc += curr?.value + ","),
        ""
      );
      setDataState({
        ...e.dataState,
        otherParams: {
          region_id: regionQueryStr.slice(0, -1) + ":in:string",
        },
      });
    } else {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0
      ) {
        setDataState({
          ...e.dataState,
          otherParams: {},
        });
      } else {
        setDataState(e.dataState);
      }
    }
  };
  const dataReceived = (dataObj) => {
    const arr = dataObj.data.data.map((driver) => {
      //Getting labels from enum
      const updatedProfileDetails = getLabelFromEnum(
        ["bgvStatus", "testDriveStatus", "onboardingStage", "driverPayoutType"],
        driver,
        "driver"
      );
      let driverObj = {};
      if (driver?.fullName) {
        driverObj = {
          ...driverObj,
          fullName: stringToSentenceCase(driver?.fullName),
        };
      } else {
        driverObj = { ...driverObj, fullName: "" };
      }
      if (driver?.regionIds && driver?.regionIds?.length > 0) {
        const regionIdStr = driver?.regionIds
          .reduce((acc, curr) => (acc = acc + curr + ", "), "")
          .slice(0, -2);
        driverObj = { ...driverObj, regionId: regionIdStr };
      }
      return {
        ...updatedProfileDetails,
        ...driverObj,
        lastLocation: driver.lastKnownLat + "," + driver.lastKnownLng,
      };
    });
    setDriverProfiles({ ...dataObj, data: arr });
  };
  const onDriverRowClick = (item) => {
    // navigate('/home/driver/' + item?.dataItem?.driverId + '/profile?pageNumber=' + ((dataState.skip / dataState.take) + 1));
    navigate(
      "/home/driver/" +
        item?.dataItem?.driverId +
        "/profile?driverName=" +
        item?.dataItem?.fullName
    );
  };
  return (
    <div className="driver-profiles-table">
      <Grid
        filter={initialFilter}
        filterable={true}
        sortable={false}
        pageable={true}
        {...dataState}
        data={driverProfiles}
        onDataStateChange={dataStateChange}
        onRowClick={onDriverRowClick}
      >
        {columns.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
            width={column?.width}
            filterable={column?.filterable}
            filterCell={
              column.defaultFilterOperator === "eq" &&
              ((props) => CategoryFilterCell(props, column, 250))
            }
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
    </div>
  );
};

export default DriverProfilesTable;
