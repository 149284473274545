import React from 'react'
import CardLayout from '../../components/common/CardLayout';
import ReferralCouponHomePage from '../../components/coupon-management/ReferralCouponHomePage';

const ReferralCouponManagement = () => {
  return (
    <CardLayout title="ATLAS Referral Coupon Management">
        <ReferralCouponHomePage />
    </CardLayout>
  )
}

export default ReferralCouponManagement;