import React, { useEffect, useState } from 'react'
import axiosService from '../../../../../init/axios';
import { APIConfig } from '../../../../../utils/constants/api.constants';
import { convertEnumToLabel, showErrorMessage } from '../../../../../utils/helpers';
import DocumentImageModal from '../../../../common/DocumentImageModal';
import LoadingPanel from '../../../../common/LoadingPanel';


const InsuranceDetails = ({ insuranceOpted, tripId }) => {

  const [driverSelfie, setDriverSelfie] = useState([]);
  const [preTripDetails, setPreTripDetails] = useState([]);
  const [postTripDetails, setPostTripDetails] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [imageDetails, setImageDetails] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //API call to fetch details
    if (insuranceOpted) {
      getTripInsuranceDetails();
    }
  }, []);

  const getTripInsuranceDetails = async () => {
    setLoading(true);
    await axiosService.get(process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.getTripInsuranceDetails(tripId))
      .then(data => {
        if (data?.data?.documents && data?.data?.documents?.length > 0) {
          const driverSelfieArr = [], preTripDetailsArr = [], postTripDetailsArr = [], insuranceImagesArr = data?.data?.documents;
          for (const item of insuranceImagesArr) {
            if (item?.scenario === 'PRE_TRIP' && !item?.tag.includes('SELFIE')) {
              preTripDetailsArr.push({...item,title:convertEnumToLabel(item?.scenario + '_' + item?.tag)+' Image'});
            }
            if (item?.scenario === 'POST_TRIP' && !item?.tag.includes('SELFIE')) {
              postTripDetailsArr.push({...item,title:convertEnumToLabel(item?.scenario + '_' + item?.tag)+' Image'});
            }
            if (item?.tag.includes('SELFIE')) {
              driverSelfieArr.push({...item,title:convertEnumToLabel(item?.scenario + '_' + item?.tag)+' Image'});
            }
          }
          setDriverSelfie(driverSelfieArr);
          setPreTripDetails(preTripDetailsArr);
          setPostTripDetails(postTripDetailsArr);
        }
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        showErrorMessage(error);
      })
  }

  const getImage = async (title, imagePath) => {
    await axiosService.get(process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.getTripInsuranceImage(tripId) + '?filePath=' + imagePath)
      .then(data => {
        if (data?.data) {
          openImageModal(title, data?.data?.preSignedUrl);
        }
      }).catch(error => {
        setLoading(false);
        showErrorMessage(error);
      })
  }

  const openImageModal = (title, imageUrl) => {
    setImageDetails({
      title: title,
      imageUrl: imageUrl,
    });
    setModalOpen(true);
  }

  const closeImageModal = () => {
    setModalOpen(false)
  }

  const ImageComponent = (tag, scenario, imagePath) => {
    const title = convertEnumToLabel(scenario + '_' + tag);
    return (
      <img src={'https://akm-img-a-in.tosshub.com/businesstoday/images/story/202112/cars24_logo-sixteen_nine.jpg'}
        key={title}
        width='150'
        height='150'
        alt={title}
        className='mr-3 cursor-pointer'
        onClick={() => getImage(title, imagePath)}
      />
    )
  }

  return (
    <>
      {
        loading ? <LoadingPanel /> : (
          <div className='insurance-details'>
            <p>Insurance Opt In : {insuranceOpted ? 'Yes' : 'No'}</p>
            <br />
            {
              insuranceOpted && (
                <div className='row mx-3'>
                  <div className='col-sm-3'>
                    <p><b>Driver Selfie</b></p>
                    {
                      driverSelfie?.length > 0 && driverSelfie.map((item,index) => (
                        <div key={'DriverImage_'+index} className='my-3 cursor-pointer' onClick={()=>getImage(item?.title,item?.filePath)}><u>{item?.title}</u></div>
                      ))
                    }
                  </div>
                  <div className='col-sm-4'>
                    <p><b>Vehicle Image at origin</b></p>
                    {
                      preTripDetails?.length > 0 && preTripDetails.map((item,index) => (
                        <div key={'PreImage_'+index} className='my-3 cursor-pointer' onClick={()=>getImage(item?.title,item?.filePath)}><u>{item?.title}</u></div>
                      ))
                    }
                  </div>
                  <div className='col-sm-4'>
                    <p><b>Vehicle Image at destination</b></p>
                    {
                      postTripDetails?.length > 0 && postTripDetails.map((item,index) => (
                        <div key={'PostImage_'+index} className='my-3 cursor-pointer' onClick={()=>getImage(item?.title,item?.filePath)}><u>{item?.title}</u></div>
                      ))
                    }
                  </div>
                </div>
              )
            }
            {modalOpen && <DocumentImageModal {...imageDetails} onClose={closeImageModal} />}
          </div>
        )
      }
    </>
  )
}

export default InsuranceDetails