import React, { useEffect, useState } from 'react'
import { APIConfig } from '../../../../../utils/constants/api.constants';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridLoader from '../../../../common/GridLoader';
import { checkMatchingRoles, getLabelFromEnum } from '../../../../../utils/helpers';
import { Button } from '@progress/kendo-react-buttons';
import { useSelector } from 'react-redux';
import { userPermissions } from '../../../../../config/userPermissionConfig';

const RefundLogs = ({ tripId, refresh, dataState, setDataState, onUpdateRefund }) => {
    const baseUrl = process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.getAllRefunds(tripId);
    const [refundLogs, setRefundLogs] = useState({
        data: [],
        total: 0
    });
    const userRoles = useSelector(state => state.auth?.userRoles);

    const CommandCell = (props) => (
        <Button themeColor={'primary'} disabled={!checkMatchingRoles(userRoles,userPermissions.refundModification)} onClick={(e)=>onUpdateRefund(e,{...props})}>Update</Button>
    );

    const columns = [
        {
            id: "id",
            field: "id",
            title: "Id",
        },
        {
            id: "amount",
            field: "amount",
            title: "Refund Amount",
        },
        {
            id: "refundReason",
            field: "refundReason",
            title: "Refund Reason",
        },
        {
            id: "refundSubReason",
            field: "refundSubReason",
            title: "Refund Sub Reason",
        },
        {
            id: "updatedAt",
            field: "updatedAt",
            title: "Updated At",
        },
        {
            id: "transactionId",
            field: "transactionId",
            title: "Transaction Id",
        },
        {
            id: "updatedBy",
            field: "updatedBy",
            title: "Updated By",
        },
        {
            id: "status",
            field: "status",
            title: "Status",
        },
        {
            id:"updateButton",
            cell: CommandCell,
            title: 'Update Status'
        }
    ];

    // useEffect(()=>{
    //     setDataState({...dataState});
    //     setRefresh((prevState)=>!prevState?true:true);
    // },[refreshTable])


    const dataStateChange = e => {
        setDataState(e.dataState);
    };

    const dataReceived = dataObj => {
        const updatedLogs = dataObj.data?.refundDetails?.map(log => {
            const details = {...log,refundReason:log?.reason,refundSubReason:log?.subReason,refundStatus:log?.status}
            const updatedLogDetails = getLabelFromEnum(['refundReason','refundSubReason','refundStatus'],details,'trip');
            return {...updatedLogDetails};
        })
        setRefundLogs({...dataObj,data:updatedLogs});
    };

    return (
        <div className='refund-div'>
            <Grid filterable={false} sortable={false} pageable={false} {...dataState}
                data={refundLogs}
                onDataStateChange={dataStateChange}>
                {
                    columns.map(column => (
                        <Column key={column?.id} field={column?.field} title={column?.title} cell={column?.cell} />
                    ))
                }
            </Grid>

            <GridLoader baseUrl={baseUrl} columns={columns} refreshTable={refresh} dataState={dataState} onDataReceived={dataReceived} />
        </div>
    )
}

export default RefundLogs;