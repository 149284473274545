import React, { useState } from 'react'
import DriverPayoutTable from './DriverPayoutTable';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import DriverPayoutAllLogsTable from './DriverPayoutAllLogsTable';

const customStyle = {
    padding: '15px',
};

const DriverPayoutHome = () => {
    const [expanded, setExpanded] = useState([""]);

    const handleSelect = event => {
        if (event.expandedItems) {
            setExpanded(event.expandedItems);
        }
    };
    return (
        <div className='driver-payout-home'>
            <PanelBar expanded={expanded} onSelect={handleSelect} expandMode={'single'}>
                <PanelBarItem title='Payouts'>
                    <div style={customStyle} className="custom-template">
                        <DriverPayoutTable />
                    </div>
                </PanelBarItem>
                <PanelBarItem title='History'>
                    <div style={customStyle} className="custom-template">
                        <DriverPayoutAllLogsTable />
                    </div>
                </PanelBarItem>
            </PanelBar>
        </div>
    )
}

export default DriverPayoutHome;