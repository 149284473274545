import React, { useState, useEffect } from "react";
import axiosService from "../../../init/axios";
import ModalLayout from "../../common/ModalLayout";
import LoadingPanel from "../../common/LoadingPanel";

export default function DriverUniformAdherenceImage({
  basePath,
  setShowImage,
}) {
  const [imgUrl, setImageUrl] = useState("");
  useEffect(() => {
    if (basePath) {
      const getAdherenceImgSrc = async () => {
        await axiosService
          .get(
            `${
              process.env.REACT_APP_DRIVER_BASE_URL
            }/admin/document?file-path=${encodeURIComponent(basePath)}`
          )
          .then((data) => {
            setImageUrl(data?.data?.preSignedUrl);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      getAdherenceImgSrc();
    }
  }, [basePath]);
  return (
    <ModalLayout
      title={"Driver Uniform Adherence Image"}
      onClose={() =>
        setShowImage({
          show: false,
          imgUrl: "",
        })
      }
    >
      <div
        style={{
          width: "500px",
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "18px",
          fontWeight: "700",
        }}
      >
        {imgUrl ? (
          <img
            src={imgUrl}
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        ) : (
          <LoadingPanel />
        )}
      </div>
    </ModalLayout>
  );
}
