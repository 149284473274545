import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import {
  FormDateTimePicker,
  FormDropDownList,
  FormNumericTextBox,
  FormTimePicker,
} from "../../utils/forms/FormComponents";
import { requiredValidator } from "../../utils/forms/Validators";
import { DateTimePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import {
  handleOnWheel,
  showErrorMessage,
  showToastMessage,
} from "../../utils/helpers";
import { TripConfig } from "../../config/tripConfig";
import LoadingPanel from "../common/LoadingPanel";
import { useSelector } from "react-redux";

const PricingConfigurationForm = () => {
  const [loading, setLoading] = useState(false);
  const numericTextBoxesRef = useRef([]);
  const [selectedTripType, setSelectedTripType] = useState(
    TripConfig.tripType[0]
  );
  const [selectedTripSubType, setSelectedTripSubType] = useState(
    TripConfig.journeyMode[0]
  );
  const [selectedTripDurationType, setSelectedTripDurationType] = useState(
    TripConfig.durationType[0]
  );
  const [tripripDurationData, setTripripDurationData] = useState(
    TripConfig.durationType
  );
  const [selectedConfigDetails, setSelectedConfigDetails] = useState({
    nightChargesFrom: new Date(new Date().setHours(21, 0, 0, 0)),
    nightChargesTill: new Date(new Date().setHours(6, 0, 0, 0)),
    weekendChargesFrom: new Date(new Date().setHours(21, 0, 0, 0)),
    weekendChargesTill: new Date(new Date().setHours(6, 0, 0, 0)),
  });
  const selectedPricingRegionFilter = useSelector(
    (state) => state.region?.pricing
  );

  useEffect(() => {
    //Call API to fetch saved pricing details
    getPricingDetails(
      selectedTripType?.value,
      selectedTripSubType?.value,
      selectedTripDurationType?.value
    );
  }, [selectedTripType, selectedTripSubType, selectedTripDurationType]);

  //To prevent default behaviour of mouseWheel down of Numeric Text Box
  useEffect(() => {
    const refs = numericTextBoxesRef?.current;
    for (let i = 0; i < refs.length; i++) {
      refs[i]?.element.addEventListener("wheel", handleOnWheel);
    }
    return () => {
      for (let i = 0; i < refs.length; i++) {
        refs[i]?.element.removeEventListener("wheel", handleOnWheel);
      }
    };
  });

  const getPricingDetails = async (tripType, tripSubType, durationType) => {
    setLoading(true);
    const baseUrl = process.env.REACT_APP_PRICING_BASE_URL;
    const endpoint = APIConfig.pricingManagement.getPricingConfig;
    let url = `${baseUrl}${endpoint}?derivedTripType=${tripType}&journeyMode=${tripSubType}&durationType=${durationType}&regionId=${selectedPricingRegionFilter?.value}`;
    if (selectedPricingRegionFilter?.child) {
      url += `&parentRegionId=${selectedPricingRegionFilter?.parentId}`;
    }
    await axiosService
      .get(url)
      .then((data) => {
        if (data?.data?.pricingConfig) {
          setSelectedConfigDetails(data?.data?.pricingConfig);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const handleTripTypeChange = (event) => {
    getDurationDropdownData(
      event.target.value?.value,
      selectedTripSubType?.value,
      selectedTripDurationType?.value
    );
    setSelectedTripType(event.target.value);
  };

  const handleTripSubTypeChange = (event) => {
    getDurationDropdownData(
      selectedTripType?.value,
      event.target.value?.value,
      selectedTripDurationType?.value
    );
    setSelectedTripSubType(event.target.value);
  };

  const handleTripDurationTypeChange = (event) => {
    getDurationDropdownData(
      selectedTripType?.value,
      selectedTripSubType?.value,
      event.target.value?.value
    );
    setSelectedTripDurationType(event.target.value);
  };

  const getDurationDropdownData = (tripType, tripSubType, duration) => {
    // if(tripType === 'IN_CITY' && tripSubType === 'ONE_WAY'){
    //     setTripripDurationData([{...TripConfig.durationType[0]}]);
    //     setSelectedTripDurationType({...TripConfig.durationType[0]});
    // }else if((tripType === 'OUTSTATION_FAR' && tripSubType === 'ROUND_TRIP') || (tripType === 'OUTSTATION_FAR' && tripSubType === 'ONE_WAY')){
    //     setTripripDurationData([{...TripConfig.durationType[1]}]);
    //     setSelectedTripDurationType({...TripConfig.durationType[1]});
    // }else{
    //     setTripripDurationData([...TripConfig.durationType]);
    // }
    setTripripDurationData([...TripConfig.durationType]);
  };

  const handleSubmit = async (dataItem) => {
    setLoading(true);
    const payload = {
      derivedTripType: selectedTripType?.value,
      journeyMode: selectedTripSubType?.value,
      durationType: selectedTripDurationType?.value,
      regionId: selectedPricingRegionFilter?.value,
      fareConfigValue: {
        ...dataItem,
      },
    };
    await axiosService
      .post(
        process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.pricingManagement.savePricingConfig,
        JSON.stringify({ ...payload }),
        { "Content-Type": "application/json" }
      )
      .then((data) => {
        setLoading(false);
        setSelectedConfigDetails(data?.data);
        showToastMessage("Pricing configuration updated successfully");
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  return (
    <div className="pricing-config">
      <div className="row">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-12 d-flex jcsp rate-card">
              <span className="left mt-1">
                <h6>Trip Type</h6>
              </span>
              <span className="d-flex">
                <DropDownList
                  data={TripConfig.derivedTripType}
                  textField="label"
                  dataItemKey="value"
                  value={selectedTripType}
                  onChange={handleTripTypeChange}
                />
              </span>
            </div>
            <div className="col-12 mt-4 d-flex jcsp rate-card">
              <span className="left mt-1">
                <h6>Trip Sub Type</h6>
              </span>
              <span className="d-flex">
                <DropDownList
                  data={TripConfig.journeyMode}
                  textField="label"
                  dataItemKey="value"
                  value={selectedTripSubType}
                  onChange={handleTripSubTypeChange}
                />
              </span>
            </div>
            <div className="col-12 mt-4 d-flex jcsp rate-card">
              <span className="left mt-1">
                <h6>Trip Duration</h6>
              </span>
              <span className="d-flex">
                <DropDownList
                  data={tripripDurationData}
                  textField="label"
                  dataItemKey="value"
                  value={selectedTripDurationType}
                  onChange={handleTripDurationTypeChange}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...selectedConfigDetails,
        }}
        key={JSON.stringify(selectedConfigDetails)}
        render={(formRenderProps) => (
          <>
            {loading ? (
              <LoadingPanel cssStyles={{ width: "30%", top: "70px" }} />
            ) : (
              <FormElement>
                <div className="details">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="row">
                        <div className="col-12 mt-3 d-flex jcsp">
                          <span className="left mt-1">Base Rate</span>
                          <span className="d-flex">
                            <span className="mt-1 mr-1">Rs.</span>
                            <Field
                              id={"baseRate"}
                              name={"baseRate"}
                              value={selectedConfigDetails?.baseRate}
                              format={"n0"}
                              component={FormNumericTextBox}
                              defaultValue={0}
                              min={0}
                              refP={(el) =>
                                (numericTextBoxesRef.current[0] = el)
                              }
                            />
                            {/* <span className='mt-1 ml-1'>per hour</span> */}
                          </span>
                        </div>
                        <div className="col-12 mt-3 d-flex jcsp">
                          <span className="left mt-1">
                            One Way Per Km Charge
                          </span>
                          <span className="d-flex">
                            <span className="mt-1 mr-1">Rs.</span>
                            <Field
                              id={"oneWayPerKmCharges"}
                              name={"oneWayPerKmCharges"}
                              value={selectedConfigDetails?.oneWayPerKmCharges}
                              format={"n0"}
                              component={FormNumericTextBox}
                              defaultValue={0}
                              min={0}
                              refP={(el) =>
                                (numericTextBoxesRef.current[1] = el)
                              }
                            />
                            {/* <span className='mt-1 ml-1'>per hour</span> */}
                          </span>
                        </div>
                        <div className="col-12 mt-3 d-flex jcsp">
                          <span className="left mt-1">Max One Way Charge</span>
                          <span className="d-flex">
                            <span className="mt-1 mr-1">Rs.</span>
                            <Field
                              id={"maxOneWayCharge"}
                              name={"maxOneWayCharges"}
                              value={selectedConfigDetails?.maxOneWayCharges}
                              format={"n0"}
                              component={FormNumericTextBox}
                              defaultValue={0}
                              min={0}
                              refP={(el) =>
                                (numericTextBoxesRef.current[2] = el)
                              }
                            />
                            {/* <span className='mt-1 ml-1'>per hour</span> */}
                          </span>
                        </div>
                        <div className="col-12 mt-3 d-flex jcsp">
                          <span className="left mt-1">Platform Fees</span>
                          <span className="d-flex">
                            <span className="mt-1 mr-1">Rs.</span>
                            <Field
                              id={"platformFees"}
                              name={"platformFees"}
                              value={selectedConfigDetails?.platformFees}
                              format={"n0"}
                              component={FormNumericTextBox}
                              defaultValue={0}
                              min={0}
                              refP={(el) =>
                                (numericTextBoxesRef.current[3] = el)
                              }
                            />
                            {/* <span className='mt-1 ml-1'></span> */}
                          </span>
                        </div>
                        <div className="col-12 mt-3 d-flex jcsp">
                          <span className="left mt-1">Weekend Charges</span>
                          <span className="d-flex">
                            <span className="mt-1 mr-1">Rs.</span>
                            <Field
                              id={"weekendCharges"}
                              name={"weekendCharges"}
                              value={selectedConfigDetails?.weekendCharges}
                              format={"n0"}
                              component={FormNumericTextBox}
                              defaultValue={0}
                              min={0}
                              refP={(el) =>
                                (numericTextBoxesRef.current[4] = el)
                              }
                            />
                            {/* <span className='mt-1 ml-1'>per day</span> */}
                          </span>
                        </div>
                        <div className="col-12 mt-3 d-flex jcsp">
                          <span className="left mt-1">Night Charges</span>
                          <span className="d-flex">
                            <span className="mt-1 mr-1">Rs.</span>
                            <Field
                              id={"nightCharges"}
                              name={"nightCharges"}
                              value={selectedConfigDetails?.nightCharges}
                              format={"n0"}
                              component={FormNumericTextBox}
                              defaultValue={0}
                              min={0}
                              refP={(el) =>
                                (numericTextBoxesRef.current[5] = el)
                              }
                            />
                            {/* <span className='mt-1 ml-1'>per day</span> */}
                          </span>
                        </div>
                        {/* <div className='col-12 mt-3 d-flex jcsp'>
                                                        <span className='left mt-1'>Insurance Fees</span>
                                                        <span className='right d-flex'>
                                                            <span className='mt-1 mr-1'>Rs.</span>
                                                            <Field
                                                                id={"insuranceCharges"}
                                                                name={"insuranceCharges"}
                                                                value={selectedConfigDetails?.insuranceCharges}
                                                                format={"n0"}
                                                                component={FormNumericTextBox}
                                                                defaultValue={0}
                                                            />
                                                        </span>
                                                    </div> */}
                        <div className="col-12 mt-3 d-flex jcsp">
                          <span className="left mt-1">Outstation Fees</span>
                          <span className="d-flex">
                            <span className="mt-1 mr-1">Rs.</span>
                            <Field
                              id={"outstationCharges"}
                              name={"outstationCharges"}
                              value={selectedConfigDetails?.outstationCharges}
                              format={"n0"}
                              component={FormNumericTextBox}
                              defaultValue={0}
                              min={0}
                              refP={(el) =>
                                (numericTextBoxesRef.current[6] = el)
                              }
                            />
                            {/* <span className='mt-1 ml-1'>per day</span> */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                {/* <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <div className='col-12 night-charges-row'>
                                                    <span className='left mt-1'>Night Charges Applicability</span>
                                                    <span className='right d-flex'>
                                                        <Field
                                                            id={"nightChargesFrom"}
                                                            name={"nightChargesFrom"}
                                                            value={selectedConfigDetails?.nightChargesFrom}
                                                            defaultValue={new Date(new Date().setHours(21,0,0,0))}
                                                            component={FormTimePicker}
                                                            width={150}
                                                            // validator={requiredValidator}
                                                        />
                                                        <span className='mx-4 mt-1'>to</span>
                                                        <Field
                                                            id={"nightChargesTill"}
                                                            name={"nightChargesTill"}
                                                            value={selectedConfigDetails?.nightChargesTill}
                                                            defaultValue={new Date(new Date().setHours(6,0,0,0))}
                                                            component={FormTimePicker}
                                                            width={150}
                                                            // validator={requiredValidator}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='col-12 mt-3 weekend-charges-row'>
                                                    <span className='left mt-1'>Weekend Charges Applicability</span>
                                                    <span className='right d-flex'>
                                                        <span className='d-flex'>
                                                            <span className='mt-1'>Friday</span>
                                                            <Field
                                                                id={"weekendChargesFrom"}
                                                                name={"weekendChargesFrom"}
                                                                value={selectedConfigDetails?.weekendChargesFrom}
                                                                defaultValue={new Date(new Date().setHours(21,0,0,0))}
                                                                component={FormTimePicker}
                                                                width={100}
                                                                // validator={requiredValidator}
                                                            />
                                                        </span>
                                                        
                                                        <span className='mx-4 mt-1'>to</span>
                                                        <span className='d-flex'>
                                                            <span className='mt-1'>Monday</span>
                                                            <Field
                                                                id={"weekendChargesTill"}
                                                                name={"weekendChargesTill"}
                                                                value={selectedConfigDetails?.weekendChargesTill}
                                                                defaultValue={new Date(new Date().setHours(6,0,0,0))}
                                                                component={FormTimePicker}
                                                                width={100}
                                                                // validator={requiredValidator}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                <div className="k-form-buttons">
                  <Button
                    themeColor={"primary"}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    Save
                  </Button>
                </div>
                <br />
              </FormElement>
            )}
          </>
        )}
      />
    </div>
  );
};

export default PricingConfigurationForm;
