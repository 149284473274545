import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slices/authSlice";
import regionSlice from "../slices/regionSlice";

const store = configureStore({
    reducer:{
        auth:authSlice,
        region:regionSlice
    },
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false})
})

export default store;