import React, { useEffect, useState } from "react";
import {
  getLabelFromEnum,
  replaceNullValues,
  showErrorMessage,
  stringToSentenceCase,
  showToastMessage,
} from "../../../../../utils/helpers";
import { Button } from "@progress/kendo-react-buttons";
import axiosService from "../../../../../init/axios";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import LoadingSpinner from "../../../../common/LoadingSpinner";

const TripDetails = ({ tripId, tripDetails }) => {
  const [updatedTripDetails, setUpdatedTripDetails] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const updatedDetails = getLabelFromEnum(
      ["derivedTripType", "journeyMode", "stage", "tripBookingMode"],
      { ...tripDetails },
      "trip"
    );
    setUpdatedTripDetails(updatedDetails);
  }, []);

  const markDriverNotFound = async () => {
    setLoading(true);
    await axiosService
      .put(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.markDnf(tripId)
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("Marked DNF successfully");
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  return (
    <div className="trip-details-tab">
      {/* <h6>Trip Details</h6>
      <br />
      <div className='row'>
        <div className='col-sm-9'>
          <div className='row'>
            <div className='col-sm-5'>
              Customer Name : <b>{replaceNullValues(stringToSentenceCase(updatedTripDetails?.customerName))}</b>
            </div>
            <div className='col-sm-5'>
              Phone Number : <b>{replaceNullValues(updatedTripDetails?.customerMobile)}</b>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-5'>
              Trip Type : <b>{replaceNullValues(updatedTripDetails?.derivedTripType)}</b>
            </div>
            <div className='col-sm-5'>
              Trip Sub Type : <b>{replaceNullValues(updatedTripDetails?.journeyMode)}</b>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-5'>
              Trip Booking Mode : <b>{replaceNullValues(updatedTripDetails?.tripBookingMode)}</b>
            </div>
            <div className='col-sm-5'>
              Trip Creation Time : <b>{replaceNullValues(updatedTripDetails?.tripCreationTime)}</b>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-5'>
              Pick Location : <b>{updatedTripDetails?.actualSourceLocation?.heading ? updatedTripDetails?.actualSourceLocation?.heading : replaceNullValues(updatedTripDetails?.expectedSourceLocation?.heading)}</b>
            </div>
            <div className='col-sm-5'>
              Drop Location: <b>{updatedTripDetails?.actualDestinationLocation?.heading ? updatedTripDetails?.actualDestinationLocation?.heading : replaceNullValues(updatedTripDetails?.expectedDestinationLocation?.heading)}</b>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-5'>
              Trip Status : <b>{replaceNullValues(updatedTripDetails?.stage)}</b>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-5'>
              Driver Name : <b>{replaceNullValues(stringToSentenceCase(updatedTripDetails?.driverDetails?.fullName))}</b>
            </div>
          </div>
          {
            updatedTripDetails?.stage === 'Trip Canceled' && (
              <div className='row'>
                <div className='col-sm-5'>
                  Cancellation Source : <b>{replaceNullValues(updatedTripDetails?.tripCancellationDetails?.source)}</b>
                </div>
                <div className='col-sm-5'>
                  Cancellation Reason : <b>{replaceNullValues(updatedTripDetails?.tripCancellationDetails?.cancellationDetails?.label)}</b>
                </div>
              </div>
            )
          }
          <div className='row'>
            <div className='col-sm-5'>
              Booked Time : <b>{replaceNullValues(updatedTripDetails?.expectedTripStartTime)}</b>
            </div>
            <div className='col-sm-5'>
              Actual Time : <b>{replaceNullValues(updatedTripDetails?.actualTripStartTime)}</b>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-md-7">
          <div className="trip-customer-details">
            <h6>
              <u>Customer Details:</u>
            </h6>
            <div className="row">
              <div className="col-md-7 trip-details-div-alignment">
                <span>Customer Name:</span>
                <span className="txtright">
                  <b>
                    {replaceNullValues(
                      stringToSentenceCase(updatedTripDetails?.customerName)
                    )}
                  </b>
                </span>
              </div>
              <div className="col-md-5 trip-details-div-alignment">
                <span>Customer Phone:</span>
                <span className="txtright">
                  <b>{replaceNullValues(updatedTripDetails?.customerMobile)}</b>
                </span>
              </div>
            </div>
          </div>
          <br />
          <div className="trip-sub-details">
            <h6>
              <u>Trip Details:</u>
            </h6>
            <div className="row">
              <div className="col-md-7">
                <div className="trip-details-div-alignment">
                  <span>Trip Type:</span>
                  <span className="txtright">
                    <b>
                      {replaceNullValues(updatedTripDetails?.derivedTripType)}
                    </b>
                  </span>
                </div>
              </div>
              <div className="col-md-5"></div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="trip-details-div-alignment">
                  <span>Trip Sub Type:</span>
                  <span className="txtright">
                    <b>{replaceNullValues(updatedTripDetails?.journeyMode)}</b>
                  </span>
                </div>
              </div>
              <div className="col-md-5"></div>
            </div>
            <div className="row">
              <div className="col-md-7 trip-details-div-alignment">
                <span>Trip Booking Mode:</span>
                <span className="txtright">
                  <b>
                    {replaceNullValues(updatedTripDetails?.tripBookingMode)}
                  </b>
                </span>
              </div>
              {/* <div className='col-md-5 trip-details-div-alignment'>
                <span>Trip Creation Time:</span>
                <span className='txtright'><b>{replaceNullValues(updatedTripDetails?.tripCreationTime)}</b></span>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-7 trip-details-div-alignment">
                <span>Expected Trip Start Time:</span>
                <span className="txtright">
                  <b>
                    {replaceNullValues(
                      updatedTripDetails?.expectedTripStartTime
                    )}
                  </b>
                </span>
              </div>
              <div className="col-md-5 trip-details-div-alignment">
                <span>Actual Trip Start Time:</span>
                <span className="txtright">
                  <b>
                    {replaceNullValues(updatedTripDetails?.actualTripStartTime)}
                  </b>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="trip-details-div-alignment">
                  <span>Pickup:</span>
                  <span className="txtright">
                    <b>
                      {updatedTripDetails?.actualSourceLocation?.heading
                        ? updatedTripDetails?.actualSourceLocation?.heading +
                          (updatedTripDetails?.actualSourceLocation?.subHeading
                            ? ", " +
                              updatedTripDetails?.actualSourceLocation
                                ?.subHeading
                            : "")
                        : replaceNullValues(
                            updatedTripDetails?.expectedSourceLocation?.heading
                              ? updatedTripDetails?.expectedSourceLocation
                                  ?.heading +
                                  (updatedTripDetails?.expectedSourceLocation
                                    ?.subHeading
                                    ? ", " +
                                      updatedTripDetails?.expectedSourceLocation
                                        ?.subHeading
                                    : "")
                              : null
                          )}
                    </b>
                  </span>
                </div>
              </div>
              <div className="col-md-5">
                <div className="trip-details-div-alignment">
                  <span>Pickup State Name:</span>
                  <span className="txtright">
                    <b>
                      {updatedTripDetails?.actualSourceLocation?.state
                        ? updatedTripDetails?.actualSourceLocation?.state
                        : replaceNullValues(
                            updatedTripDetails?.expectedSourceLocation?.state
                          )}
                    </b>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="trip-details-div-alignment">
                  <span style={{ whiteSpace: "nowrap", marginRight: "50px" }}>
                    Additional details:
                  </span>
                  <span className="txtright">
                    <b style={{ wordBreak: "break-all" }}>
                      {(() => {
                        const location =
                          updatedTripDetails?.actualSourceLocation ||
                          updatedTripDetails?.expectedSourceLocation;
                        if (location) {
                          const { houseNo, landmark, locality } = location;
                          if (houseNo || landmark || locality) {
                            return (
                              <>
                                {houseNo && `${houseNo}`}
                                {locality && `, ${locality}`}
                                {landmark && `, ${landmark}`}
                              </>
                            );
                          } else {
                            return "N.A.";
                          }
                        } else {
                          return "N.A.";
                        }
                      })()}
                    </b>
                  </span>
                </div>
              </div>
            </div>

            {updatedTripDetails?.stops &&
              updatedTripDetails?.stops?.stops &&
              Object.keys(updatedTripDetails?.stops?.stops)?.length > 0 &&
              updatedTripDetails?.stops?.stops?.map((stop, index) => (
                <div className="row" key={index}>
                  <div className="col-md-7">
                    <div className="trip-details-div-alignment">
                      <span>Stop {index + 1}:</span>
                      <span className="txtright">
                        <b>
                          {stop?.heading
                            ? stop?.heading +
                              (stop?.subHeading ? ", " + stop?.subHeading : "")
                            : "N.A"}
                        </b>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="trip-details-div-alignment">
                      <span>State:</span>
                      <span className="txtright">
                        <b>{stop?.state || "N.A"}</b>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            <div className="row">
              <div className="col-md-7">
                <div className="trip-details-div-alignment my-2">
                  <span>Drop:</span>
                  <span className="txtright">
                    <b>
                      {updatedTripDetails?.actualDestinationLocation?.heading
                        ? updatedTripDetails?.actualDestinationLocation
                            ?.heading +
                          (updatedTripDetails?.actualDestinationLocation
                            ?.subHeading
                            ? ", " +
                              updatedTripDetails?.actualDestinationLocation
                                ?.subHeading
                            : "")
                        : replaceNullValues(
                            updatedTripDetails?.expectedDestinationLocation
                              ?.heading
                              ? updatedTripDetails?.expectedDestinationLocation
                                  ?.heading +
                                  (updatedTripDetails
                                    ?.expectedDestinationLocation?.subHeading
                                    ? ", " +
                                      updatedTripDetails
                                        ?.expectedDestinationLocation
                                        ?.subHeading
                                    : "")
                              : null
                          )}
                    </b>
                  </span>
                </div>
              </div>
              <div className="col-md-5">
                <div className="trip-details-div-alignment my-2">
                  <span>Drop State Name:</span>
                  <span className="txtright">
                    <b>
                      {updatedTripDetails?.actualDestinationLocation?.state
                        ? updatedTripDetails?.actualDestinationLocation?.state
                        : replaceNullValues(
                            updatedTripDetails?.expectedDestinationLocation
                              ?.state
                          )}
                    </b>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="trip-details-div-alignment my-2">
                  <span>Vehicle Type:</span>
                  <span className="txtright">
                    <b>
                      {replaceNullValues(
                        updatedTripDetails?.vehicleDetails?.carType
                      )}
                    </b>
                  </span>
                </div>
              </div>
              <div className="col-md-5 trip-details-div-alignment my-2">
                <span>Transmission Type:</span>
                <span className="txtright">
                  <b>
                    {replaceNullValues(
                      updatedTripDetails?.vehicleDetails?.transmissionType
                    )}
                  </b>
                </span>
              </div>
            </div>
            {updatedTripDetails?.stage === "Trip canceled" && (
              <div className="row">
                <div className="col-md-7 trip-details-div-alignment">
                  <span>Cancellation Reason:</span>
                  <span className="txtright">
                    <b>
                      {replaceNullValues(
                        updatedTripDetails?.tripCancellationDetails
                          ?.cancellationDetails?.label
                      )}
                    </b>
                  </span>
                </div>
                <div className="col-sm-5 trip-details-div-alignment">
                  <span>Cancellation Source:</span>
                  <span className="txtright">
                    <b>
                      {replaceNullValues(
                        updatedTripDetails?.tripCancellationDetails?.source
                      )}
                    </b>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="col-md-5"
          style={{ borderLeft: "1px dotted #ff6358", paddingLeft: "20px" }}
        >
          <div className="trip-driver-details">
            <h6>
              <u>Driver Details:</u>
            </h6>
            <div className="row">
              <div className="col-md-8 trip-details-div-alignment">
                <span>Driver Name:</span>
                <span className="txtright">
                  <b>
                    {replaceNullValues(
                      stringToSentenceCase(
                        updatedTripDetails?.driverDetails?.fullName
                      )
                    )}
                  </b>
                </span>
              </div>
              <div className="col-md-8 trip-details-div-alignment"></div>
            </div>
          </div>
          {/* <div className='trip-driver-reassignment'>
            <h6><u>Driver Re-assignment:</u></h6>
            <div className='row'>
              <div className='col-md-8 trip-details-div-alignment'>
                <span>Reason:</span>
                <span className='txtright'></span>
              </div>
              <div className='col-md-8 trip-details-div-alignment'>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8 trip-details-div-alignment'>
                <span>Sub Reason:</span>
                <span className='txtright'></span>
              </div>
              <div className='col-md-8 trip-details-div-alignment'>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8 trip-details-div-alignment'>
                <Button
                  themeColor={"primary"}
                >
                  Re-assign Driver
                </Button>
              </div>
              <div className='col-md-8 trip-details-div-alignment'>
              </div>
            </div>
          </div> */}
          <div className="trip-dnf my-2">
            <h6>
              <u>Mark DNF</u>
            </h6>
            <Button
              themeColor={"primary"}
              disabled={updatedTripDetails?.tag !== "CRITICAL_ZONE" || loading}
              onClick={markDriverNotFound}
            >
              {loading ? (
                <LoadingSpinner width={30} height={30} />
              ) : (
                "Driver Not Found"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripDetails;
