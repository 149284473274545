import React, { useEffect, useState } from "react";
import CardLayout from "../components/common/CardLayout";
import TripManagementTable from "../components/trip-management/TripManagementTable";
import { useLocation, useParams } from "react-router-dom";
import TripDetailPage from "../components/trip-management/trip-details/TripDetailPage";
import { FormMultiSelect } from "../utils/forms/FormComponents";
import { useDispatch, useSelector } from "react-redux";
import { updateTripRegionFilter } from "../slices/regionSlice";
import { getFromLocalStorage } from "../utils/helpers";
import { DropdownSearchMultiSelect } from "../utils/forms/NewFormComponents";

const TripManagement = () => {
  const location = useLocation();
  const { tripId } = useParams();
  const [tripDetailsSelected, setTripDetailsSelected] = useState(false);
  const regions = useSelector((state) => state.region?.mainRegions);
  const selectedTripRegionFilters = useSelector((state) => state.region?.trip);
  const dispatch = useDispatch();

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams:
      getFromLocalStorage("regionFilters") &&
      getFromLocalStorage("regionFilters")?.trip?.length > 0
        ? {
            parent_region_id:
              getFromLocalStorage("regionFilters")
                ?.trip?.reduce((acc, curr) => (acc += curr?.value + ","), "")
                .slice(0, -1) + ":in:string",
          }
        : {},
  });

  useEffect(() => {
    if (tripId && location.pathname.split("/").at(-1) === "details") {
      setTripDetailsSelected(true);
    } else {
      setTripDetailsSelected(false);
    }
  }, [location]);

  const onTripRegionFilterChange = (e) => {
    dispatch(updateTripRegionFilter([...e]));
    if (e.length > 0) {
      const regionQueryStr = e?.reduce(
        (acc, curr) => (acc += curr?.value + ","),
        ""
      );
      setDataState({
        ...dataState,
        skip: 0,
        take: 10,
        otherParams: {
          parent_region_id: regionQueryStr.slice(0, -1) + ":in:string",
        },
      });
    } else {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0
      ) {
        setDataState({
          ...dataState,
          skip: 0,
          take: 10,
          otherParams: {},
        });
      }
    }
  };

  const updatedRegions = regions
    ?.slice()
    ?.map((item) => {
      const isSelected = selectedTripRegionFilters.some(
        (selectedItem) => selectedItem.regionId === item.regionId
      );

      if (isSelected) {
        return null;
      }

      return {
        ...item,
        label: item.child
          ? `${item.regionName} (${item.parentRegionName})`
          : item.regionName,
      };
    })
    .filter((item) => item !== null);

  const TripRegionFilterDiv = () => (
    <div>
      <DropdownSearchMultiSelect
        options={updatedRegions}
        textField="label"
        values={selectedTripRegionFilters}
        onChange={onTripRegionFilterChange}
      />
    </div>
  );

  return (
    <CardLayout title="Trip Management" ComponentRender={TripRegionFilterDiv}>
      {tripId && location.pathname.split("/").at(-1) === "details" ? (
        <TripDetailPage setTripDetailsSelected={setTripDetailsSelected} />
      ) : (
        <TripManagementTable
          setTripDetailsSelected={setTripDetailsSelected}
          dataState={dataState}
          setDataState={setDataState}
        />
      )}
    </CardLayout>
  );
};

export default TripManagement;
