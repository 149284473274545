import React, { useEffect, useRef, useState } from "react";
import { APIConfig } from "../../../utils/constants/api.constants";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import GridLoader from "../../common/GridLoader";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import axiosService from "../../../init/axios";
import {
  handleOnWheel,
  showErrorMessage,
  showToastMessage,
} from "../../../utils/helpers";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useSelector } from "react-redux";

const PricingDiluterConfigurationTable = ({
  derivedTripType,
  journeyMode,
  durationType,
}) => {
  const baseUrl =
    process.env.REACT_APP_PRICING_BASE_URL +
    APIConfig.pricingManagement.getPricingDiluterConfig;
  const selectedPricingRegionFilter = useSelector(
    (state) => state.region?.pricing
  );
  const numericTextBoxesRef = useRef([]);
  const [pricingDiluterDetails, setPricingDiluterDetails] = useState({
    data: [],
    total: 0,
  });
  let timeOut;

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams: {
      derivedTripType,
      journeyMode,
      durationType,
      regionId: selectedPricingRegionFilter?.value,
      parentRegionId: selectedPricingRegionFilter?.parentId,
    },
  });
  const [loading, setLoading] = useState(false);

  //To prevent default behaviour of mouseWheel down of Numeric Text Box
  useEffect(() => {
    const refs = numericTextBoxesRef?.current;
    for (let i = 0; i < refs.length; i++) {
      refs[i]?.element.addEventListener("wheel", handleOnWheel);
    }
    return () => {
      for (let i = 0; i < refs.length; i++) {
        refs[i]?.element.removeEventListener("wheel", handleOnWheel);
      }
    };
  });

  const CommandCell = (props) => (
    <NumericTextBox
      defaultValue={props?.dataItem?.discountPercentage || 0}
      min={0}
      max={100}
      onChange={(e) => onPricingDiluterChange(e, { ...props })}
      style={{ margin: "5px", width: "95%" }}
      ref={(el) =>
        (numericTextBoxesRef.current[props?.dataItem?.orderKey] = el)
      }
    />
  );

  const columns = [
    {
      id: "timeRange",
      field: "timeRange",
      title: "Time Range",
    },
    {
      id: "originalBaseRate",
      field: "originalBaseRate",
      title: "Base Rate",
    },
    {
      id: "discountPercentage",
      cell: CommandCell,
      title: "Pricing Diluter (%)",
    },
    {
      id: "dilutedBaseRate",
      field: "dilutedBaseRate",
      title: "Final Base Rate",
    },
  ];

  useEffect(() => {
    return () => timeOut && clearTimeout(timeOut);
  }, []);

  useEffect(() => {
    setDataState({
      ...dataState,
      otherParams: {
        derivedTripType,
        journeyMode,
        durationType,
        regionId: selectedPricingRegionFilter?.value,
        parentRegionId: selectedPricingRegionFilter?.parentId,
      },
    });
  }, [derivedTripType, journeyMode, durationType, selectedPricingRegionFilter]);

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataObj) => {
    const updatedPricingConfig = dataObj.data?.diluterConfig?.map((diluter) => {
      const updatedDetails = {
        ...diluter,
        timeRange: `${diluter?.min} - ${diluter.max} ${diluter?.timeUnit}`,
      };
      return updatedDetails;
    });
    setPricingDiluterDetails({ ...dataObj, data: updatedPricingConfig });
  };

  const onPricingDiluterChange = (e, props) => {
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      if (e.target.value < 0 || e.target.value > 100) return;
      //Diluter updation
      const index = pricingDiluterDetails?.data?.findIndex(
        (item) => item?.timeRange === props.dataItem?.timeRange
      );
      const diluterList = [...pricingDiluterDetails?.data];
      const value = (
        props.dataItem?.originalBaseRate -
        props.dataItem?.originalBaseRate * (e.target.value / 100)
      ).toFixed(2);
      const updatedData = {
        ...pricingDiluterDetails?.data[index],
        dilutedBaseRate: value,
        discountPercentage: e.target.value,
      };
      diluterList[index] = updatedData;
      setPricingDiluterDetails({ data: diluterList, total: 0 });
    }, 1200);
  };

  const onSavePricingDiluterDetails = async () => {
    setLoading(true);
    const diluterData = pricingDiluterDetails.data?.map((item) => {
      const updatedItem = {
        discountPercentage: item?.discountPercentage,
        max: item?.max,
        min: item?.min,
        orderKey: item?.orderKey,
        timeUnit: item?.timeUnit,
      };
      return updatedItem;
    });
    const reqData = {
      derivedTripType: derivedTripType,
      journeyMode: journeyMode,
      durationType: durationType,
      regionId: selectedPricingRegionFilter?.value,
      fareConfigValue: diluterData,
    };
    await axiosService
      .post(
        process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.pricingManagement.savePricingDiluterConfig,
        JSON.stringify(reqData),
        { "Content-Type": "application/json" }
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("Configuration updated successfully");
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  return (
    <div className="pricing-diluter-table">
      <Grid
        filterable={false}
        sortable={false}
        pageable={false}
        {...dataState}
        data={pricingDiluterDetails}
        onDataStateChange={dataStateChange}
      >
        {columns.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
            cell={column?.cell}
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
      <div className="k-form-buttons">
        <Button
          themeColor={"primary"}
          type={"button"}
          onClick={onSavePricingDiluterDetails}
          disabled={loading}
        >
          {loading ? <LoadingSpinner width={25} height={25} /> : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default PricingDiluterConfigurationTable;
