import React, { useEffect, useState } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TripDetails from "./trip-details-panel/trip-details/TripDetails";
import CandidateDrivers from "./trip-details-panel/candidate-drivers/CandidateDrivers";
import PaymentLogs from "./trip-details-panel/payment-logs/PaymentLogs";
import InsuranceDetails from "./trip-details-panel/insurance-details/InsuranceDetails";
import TripStatusLogs from "./trip-details-panel/trip-status-logs/TripStatusLogs";
import AllocationLogs from "./trip-details-panel/allocation-logs/AllocationLogs";
import Pricing from "./trip-details-panel/pricing/Pricing";
import axiosService from "../../../init/axios";
import { APIConfig } from "../../../utils/constants/api.constants";
import {
  convertEnumToLabel,
  replaceNullValues,
  showErrorMessage,
} from "../../../utils/helpers";
import LoadingPanel from "../../common/LoadingPanel";
import Waivers from "./trip-details-panel/waivers/Waivers";
import ReserveDrivers from "./trip-details-panel/reserve-drivers/ReserveDrivers";
import TripInvoices from "./trip-details-panel/trip-invoices/TripInvoices";
import RevenueStream from "./trip-details-panel/revenue-stream/RevenueStream";

const customStyle = {
  padding: "15px",
};

const TripDetailPage = ({ setTripDetailsSelected }) => {
  const { tripId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState([""]);
  const [tripDetails, setTripDetails] = useState();
  const [refreshTripDetails, setRefreshTripDetails] = useState(false);
  const [refreshTripCalled, setRefreshTripCalled] = useState(false);
  const [loading, setLoading] = useState("");

  useEffect(() => {
    //API call to fetch trip details
    getTripDetails();
  }, []);

  useEffect(() => {
    if (refreshTripCalled) {
      getTripDetails();
    }
  }, [refreshTripDetails]);

  const getTripDetails = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getTripDetails(tripId)
      )
      .then((data) => {
        setLoading(false);
        setTripDetails(data.data);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const handleSelect = (event) => {
    if (event.expandedItems) {
      setExpanded(event.expandedItems);
    }
  };

  const onGoBack = () => {
    setTripDetailsSelected(false);
    navigate("/home/trip");
  };
  return (
    <div className="trip-details">
      <span className="k-icon k-i-arrow-left" onClick={onGoBack}></span>
      {loading ? (
        <LoadingPanel />
      ) : (
        <>
          <div className="trip-details-header mx-3 my-3">
            <div className="row">
              <div className="col-sm-4">
                <h4 className="">Trip Id : {tripId}</h4>
                <p>
                  Booking Created At :{" "}
                  {replaceNullValues(tripDetails?.tripCreationTime)}
                </p>
              </div>
              <div className="col-sm-8 flag-col">
                <div className="flag-div">
                  {tripDetails?.tag === "CRITICAL_ZONE" && (
                    <div className="trip-critical-zone-div">
                      <span>CRITICAL</span>
                    </div>
                  )}
                  {tripDetails?.stage && (
                    <div className="trip-stage-div">
                      <span>{convertEnumToLabel(tripDetails?.stage)}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="trip-details-panel mx-3 my-4">
            <TripDetails tripDetails={tripDetails} tripId={tripId} />

            <PanelBar
              expanded={expanded}
              onSelect={handleSelect}
              expandMode={"single"}
            >
              {/* <PanelBarItem title='Customer Details'>
                                <div style={customStyle} className="plate">
                                    <p>Customer Name : <b>{replaceNullValues(tripDetails?.customerDetails?.fullName)}</b></p>
                                    <p>Phone Number : <b>{replaceNullValues(tripDetails?.customerDetails?.mobileNumber)}</b></p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title='Trip Details'>
                                <div style={customStyle} className="custom-template">
                                    <TripDetails tripDetails={tripDetails} />
                                </div>
                            </PanelBarItem> */}
              <PanelBarItem title="Candidate Drivers">
                <div style={customStyle} className="custom-template">
                  <CandidateDrivers
                    tripId={tripId}
                    lat={
                      tripDetails?.expectedSourceLocation?.lat &&
                      tripDetails?.expectedSourceLocation?.lat
                    }
                    lng={
                      tripDetails?.expectedSourceLocation?.lng &&
                      tripDetails?.expectedSourceLocation?.lng
                    }
                    tripBookingMode={tripDetails?.tripBookingMode}
                    stage={tripDetails?.stage}
                  />
                </div>
              </PanelBarItem>
              <PanelBarItem title="Pricing">
                <div style={customStyle} className="custom-template">
                  <Pricing
                    tripId={tripId}
                    journeyMode={tripDetails?.journeyMode}
                    tripType={tripDetails?.derivedTripType}
                    selectedDuration={tripDetails?.selectedDuration}
                    selectedDurationUnit={tripDetails?.selectedDurationUnit}
                    actualTripStartTime={tripDetails?.actualTripStartTime}
                    actualTripEndTime={tripDetails?.actualTripEndTime}
                    couponCode={tripDetails?.couponCode}
                  />
                </div>
              </PanelBarItem>
              <PanelBarItem title="Revenue Stream">
                <div style={customStyle} className="custom-template">
                  <RevenueStream tripId={tripId} />
                </div>
              </PanelBarItem>
              <PanelBarItem title="Trip Invoices">
                <div style={customStyle} className="custom-template">
                  <TripInvoices tripId={tripId} stage={tripDetails?.stage} />
                </div>
              </PanelBarItem>
              <PanelBarItem title="Reserve Drivers">
                <div style={customStyle} className="custom-template">
                  <ReserveDrivers
                    tripId={tripId}
                    lat={
                      tripDetails?.expectedSourceLocation?.lat &&
                      tripDetails?.expectedSourceLocation?.lat
                    }
                    lng={
                      tripDetails?.expectedSourceLocation?.lng &&
                      tripDetails?.expectedSourceLocation?.lng
                    }
                    tripBookingMode={tripDetails?.tripBookingMode}
                    stage={tripDetails?.stage}
                    refreshTripDetails={refreshTripDetails}
                    setRefreshTripDetails={setRefreshTripDetails}
                    refreshTripCalled={refreshTripCalled}
                    setRefreshTripCalled={setRefreshTripCalled}
                  />
                </div>
              </PanelBarItem>
              <PanelBarItem title="Payment Logs">
                <div style={customStyle} className="custom-template">
                  <PaymentLogs tripId={tripId} stage={tripDetails?.stage} />
                </div>
              </PanelBarItem>
              <PanelBarItem title="Insurance Details">
                <div style={customStyle} className="custom-template">
                  <InsuranceDetails
                    tripId={tripId}
                    insuranceOpted={tripDetails?.insuranceOpted}
                  />
                </div>
              </PanelBarItem>
              <PanelBarItem title="Trip Status Logs">
                <div style={customStyle} className="custom-template">
                  <TripStatusLogs tripId={tripId} />
                </div>
              </PanelBarItem>
              <PanelBarItem title="Allocation Logs">
                <div style={customStyle} className="custom-template">
                  <AllocationLogs tripId={tripId} />
                </div>
              </PanelBarItem>
              <PanelBarItem title="Waivers">
                <div style={customStyle} className="custom-template">
                  <Waivers tripId={tripId} />
                </div>
              </PanelBarItem>
              {/* {
                                TripConfig.waiverStages.includes(tripDetails?.stage) && (
                                    <PanelBarItem title='Waivers'>
                                        <div style={customStyle} className="custom-template">
                                            <Waivers tripId={tripId} />
                                        </div>
                                    </PanelBarItem>
                                )
                            } */}
            </PanelBar>
          </div>
        </>
      )}
    </div>
  );
};

export default TripDetailPage;
