import { createSlice } from "@reduxjs/toolkit";
import { saveToLocalStorage } from "../utils/helpers";

const regionSlice = createSlice({
  name: "regionFilter",
  initialState: {
    regions: [],
    subRegions: [],
    allRegions: [],
    trip: [],
    driver: [],
    pricing: "",
    subscription: "",
  },
  reducers: {
    updateRegions: (state, action) => {
      state.regions = action.payload.regions;
      state.subRegions = action.payload.subRegions;
      //
      state.mainRegions = state.regions.filter((itemA) => {
        return !state.subRegions.some(
          (itemB) => itemB.regionId === itemA.regionId
        );
      });
      //
      state.pricing = action.payload.regions[0];
      state.subscription = action.payload.regions[0];
    },
    updateRegionFilter: (state, action) => {
      state.regions = action.payload.regions;
      state.trip = action.payload.trip;
      state.driver = action.payload.driver;
      state.pricing = action.payload.pricing || state.regions[0];
      state.subscription = action.payload.subscription || state.regions[0];
    },
    updateTripRegionFilter: (state, action) => {
      state.trip = action.payload;
      saveToLocalStorage("regionFilters", { ...state, trip: action.payload });
    },
    updateDriverRegionFilter: (state, action) => {
      state.driver = action.payload;
      saveToLocalStorage("regionFilters", { ...state, driver: action.payload });
    },
    updatePricingRegionFilter: (state, action) => {
      state.pricing = action.payload;
      saveToLocalStorage("regionFilters", {
        ...state,
        pricing: action.payload,
      });
    },
    updateSubscriptionRegionFilter: (state, action) => {
      state.subscription = action.payload;
      saveToLocalStorage("regionFilters", {
        ...state,
        subscription: action.payload,
      });
    },
  },
});

export const {
  updateRegions,
  updateRegionFilter,
  updateTripRegionFilter,
  updateDriverRegionFilter,
  updatePricingRegionFilter,
  updateSubscriptionRegionFilter,
} = regionSlice.actions;
export default regionSlice.reducer;
