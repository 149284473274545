import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logIn, setToken, setUserDetails, setUserRoles } from '../slices/authSlice';
import { saveToLocalStorage, showErrorMessage } from '../utils/helpers';
import { Descope } from '@descope/react-sdk';

export const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLoginSuccess = (e)=>{
    const {refreshJwt,sessionJwt,user} = e?.detail;
    saveToLocalStorage('token',sessionJwt);
    saveToLocalStorage('refreshToken',refreshJwt);
    saveToLocalStorage('userLoggedIn',true);
    saveToLocalStorage('userDetails',user);
    dispatch(logIn());
    dispatch(setToken(sessionJwt));
    dispatch(setUserDetails(user));
    dispatch(setUserRoles(user?.userTenants.filter(tenant => tenant?.tenantId === process.env.REACT_APP_DESCOPE_TENANT_ID)[0]?.roleNames));
    navigate('/home/trip');
  }

  const onLoginFail = (e) => {
    showErrorMessage('Login Failed');
  }

  return (
    <div className="App">
      <div className="sign-in-main-container">
        <div className="row sign-in-container">
          <div className="col-sm-7 sign-in-image-col">
            <img src={require('../assets/autopilot-bg-image.png')} alt="" className="autopilot-image" />
          </div>
          <div className="col-sm-5 sign-in-form">
            <div className="sign-in-wrapper">
              <div className="logo-wrapper">
                <div className="logo">
                  <img src={require('../assets/signin.png')} alt={'sign in icon'} />
                </div>
                <div className='banner'>
                  AUTOPILOT LOG IN
                </div>
              </div>
              <div style={{minWidth:400}}>
                <Descope
                  flowId={process.env.REACT_APP_DESCOPE_SIGN_IN_FLOW_ID}
                  onSuccess={onLoginSuccess}
                  onError={onLoginFail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}