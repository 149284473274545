import { ReactDOM } from "react";

const LoadingPanel = ({ cssStyles }) => {
  const loadingPanel = (
    <div className="loading-div">
      <div className="k-loading-mask" style={cssStyles && { ...cssStyles }}>
        <span className="k-loading-text">Loading...</span>
        <div className="k-loading-image" />
        <div className="k-loading-color" />
      </div>
    </div>
  );
  const gridContent = document && document.querySelector(".k-grid-content");
  // return gridContent
  //   ? ReactDOM.createPortal(loadingPanel, gridContent)
  //   : loadingPanel;
  return loadingPanel
};

export default LoadingPanel;