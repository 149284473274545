import React from 'react'
import ModalLayout from '../../../../common/ModalLayout'
import { Button } from '@progress/kendo-react-buttons'

const WaiverConfirmModal = ({pricingAmount, formDataItem, onClose, handleSubmit}) => {
    return (
        <ModalLayout title={'Please Confirm'} onClose={onClose} >
            <div className='waiver-confirm-div' style={{ minWidth: 700 }}>
                <p>Please confirm that you have entered the correct waiver percentage - </p>
                <br />
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='row'>
                            <div className='col-lg-9'>
                                Booking Amount After Discount (excluding GST)
                            </div>
                            <div className='col-lg-3'>
                                <b>Rs. {pricingAmount}</b>
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='col-lg-9'>
                                <b>-</b> &nbsp; Waiver Amount
                            </div>
                            <div className='col-lg-3'>
                                <b>Rs. {formDataItem?.amount}</b>
                            </div>
                        </div>
                        <br />
                        <hr style={{width:'100%'}} />
                        <div className='row'>
                            <div className='col-lg-9'>
                                Final Amount Customer has to pay (excluding GST)
                            </div>
                            <div className='col-lg-3'>
                                <b>Rs. {Number(pricingAmount - (formDataItem?.amount)).toFixed(2)}</b>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='waiver-percentage flex-basic-prop' style={{ border: '1px solid black', padding: '8px' }}>
                            <b>Waiver Percentage : {formDataItem?.waiverPercentage}</b>
                        </div>
                    </div>
                </div>
                <br />
                <small>Please Note: A waiver once added cannot be edited/modified. Also, a waiver can be added once per trip.</small>
                <br />
                <br />
                <div className='row'>
                    <div className='col-lg-12 txtcenter'>
                        <Button
                            themeColor={"primary"}
                            type={"button"}
                            onClick={handleSubmit}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
}

export default WaiverConfirmModal