export const APIConfig = {
  tripManagement: {
    getAllTrips: "/admin/trip/all",
    getTripDetails: (tripId) => "/admin/trip/" + tripId,
    getTripPricing: (tripId) => "/admin/trip/" + tripId + "/pricing",
    getCandidateDrivers: "/admin/candidate-drivers",
    getReservedDrivers: "/admin/reserved-drivers",
    assignReservedDriver: (tripId) =>
      "/admin/trip/" + tripId + "/assign-driver",
    getTripInsuranceDetails: (tripId) => "/admin/trip/" + tripId + "/documents",
    getTripInsuranceImage: (tripId) => "/admin/trip/" + tripId + "/doc",
    getPaymentLogs: (tripId) => "/admin/trip/" + tripId + "/payment-logs",
    getTripStatusLogs: (tripId) => "/admin/trip/" + tripId + "/status-logs",
    getAllocationLogs: (tripId) =>
      "/admin/trip/" + tripId + "/allocation-audit",
    getRevenueStreamLogs: (tripId) => "/admin/trip/" + tripId + "/pricing",
    markDnf: (tripId) => "/admin/trip/" + tripId + "/dnf",
    configData: "/trip/config/data",
    getWaiverDetails: (tripId) => "/admin/trip/" + tripId + "/waiver",
    saveWaiverDetails: (tripId) => "/admin/trip/" + tripId + "/waiver",
    createWaiver: (tripId) => "/admin/trip/" + tripId + "/waiver",
    getAllRefunds: (tripId) => "/admin/trip/" + tripId + "/refund/all",
    createRefund: (tripId) => "/admin/trip/" + tripId + "/refund",
    updateRefund: (tripId, refundId) =>
      "/admin/trip/" + tripId + "/refund/" + refundId,
    getInvoices: (tripId) => "/admin/trip/" + tripId + "/invoices",
    getRegions: "/trip/config/data",
  },
  driverManagement: {
    getAllDrivers: "/admin/driver/all",
    getDriverDetails: (driverId) => "/admin/driver/" + driverId,
    getDriverDocumentImage: "/admin/document",
    saveDriverDetails: (driverId) => "/admin/driver/" + driverId,
    saveDriverAdditionalDetails: (driverId) =>
      "/admin/driver/" + driverId + "/additional-details",
    getDriverAccounts: "/admin/driver/ledger",
    getDriverAccountDetails: (driverId) =>
      "/admin/driver/" + driverId + "/ledger",
    getDriverActivityStatus: "/admin/driver/activity-status",
    getDriverOnlineActivityList: (driverId) =>
      "/admin/driver/" + driverId + "/timesheet",
    uploadDriverContract: "/admin/upload-doc",
    getPennyDropLogs: (driverId) =>
      "/admin/driver/" + driverId + "/penny-drop-logs",
    getDriverPayoutBatch: "/admin/payout-batch",
    approvePayoutBatch: (batchId) => "/admin/payout-batch/" + batchId,
    getDriverPayoutBatchFile: (batchId) =>
      "/admin/payout-batch/" + batchId + "/export",
    uploadDriverPayoutFile: "/admin/upload-payout-doc",
    approvePayoutLevelFile: "/admin/upload-payout-doc",
    saveDriverBankDetails: (driverId) =>
      "/admin/driver/" + driverId + "/bank-details",
  },
  pricingManagement: {
    getPricingConfig: "/admin/fare/config/PRICING_CONFIG",
    savePricingConfig: "/admin/fare/config/PRICING_CONFIG",
    getPricingDiluterConfig: "/admin/fare/config/GCD_CONFIG",
    savePricingDiluterConfig: "/admin/fare/config/GCD_CONFIG",
    getPricingLogs: "/admin/fare/config/logs",
    getConfigDifference: (configType, configVersion) =>
      "/admin/fare/config/diff/" + configType + "/" + configVersion,
  },
  bulkOperationsManagement: {
    uploadBulkOperation: "/admin/bulk-ops",
    getBulkOperation: "/admin/bulk-ops",
  },
  couponManagement: {
    getCouponDetails: (couponCode) => "/admin/coupon/" + couponCode,
    getCouponStatusOfCustomer: "/admin/user-coupon/status",
    issueNewCouponToCustomer: "/admin/user-coupon/issue",
    getCustomerCouponLogs: "/admin/user-coupon/logs",
  },
  customerManagement: {
    getCustomerDetails: "/admin/customer/details",
    blockCustomer: "/admin/customer/block",
    unBlockCustomer: (mobileNumber) =>
      "/admin/customer/unblock?mobileNumber=" + mobileNumber,
  },
  authManagement: {
    login: "/auth/admin/login",
  },
  apacheSuperset: {
    GUEST_TOKEN: "/api/v1/security/guest_token/",
    CSRF_TOKEN: "/api/v1/security/csrf_token/",
  },
  subscription: {
    getAllSubscriptions: "/admin/subscription",
    getSubscriptionDetails: (id) => "/admin/subscription/" + id,
    assignDriverSubscriptionLevel: "/admin/subscription/assign-driver",
    getAllDriversSubscriptions: "/admin/driver/subscription/all",
  },
};
