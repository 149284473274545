import { Field, Form, FormElement } from '@progress/kendo-react-form'
import React, { useEffect, useRef, useState } from 'react'
import { FormDropDownList, FormInput, FormNumericTextBox, FormTextArea } from '../../../../../utils/forms/FormComponents';
import { TripConfig } from '../../../../../config/tripConfig';
import { requiredValidator } from '../../../../../utils/forms/Validators';
import { Button } from '@progress/kendo-react-buttons';
import axiosService from '../../../../../init/axios';
import { APIConfig } from '../../../../../utils/constants/api.constants';
import { getLabelValueObjectFromEnum, handleOnWheel, showErrorMessage, showToastMessage } from '../../../../../utils/helpers';
import LoadingPanel from '../../../../common/LoadingPanel';
import WaiverConfirmModal from './WaiverConfirmModal';

const Waivers = ({ tripId }) => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const numericTextBoxRef = useRef();
    const [selectedWaiverReason, setSelectedWaiverReason] = useState({
        label: 'Driver Issue',
        value: 'DRIVER_ISSUE'
    });

    const [selectedWaiverSubReason, setSelectedWaiverSubReason] = useState({
        label: 'Driver Drunk',
        value: 'DRUNK',
        tag: 'DRIVER_INFRACTION'
    });
    const [waiverConfig, setWaiverConfig] = useState();
    const [waiverDetails, setWaiverDetails] = useState();
    const [pricingAmount, setPricingAmount] = useState();
    const [formDataItem,setFormDataItem] = useState();

    //To prevent default behaviour of mouseWheel down of Numeric Text Box
    useEffect(()=>{
        const ref = numericTextBoxRef?.current;
        ref?.element?.addEventListener('wheel', handleOnWheel);

        return () => {
            ref?.element?.removeEventListener('wheel', handleOnWheel);
        }
    });

    useEffect(() => {
        getWaiverConfig();
        getWaiverDetails();
        getTripPricing();
    }, []);

    const getTripPricing = async () => {
        await axiosService.get(process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.getTripPricing(tripId))
            .then(data => {
                setPricingAmount(data?.data?.actualTripFare ? data?.data?.actualTripFare?.discountedAmount : data?.data?.expectedTripFare?.discountedAmount);
            })
            .catch(error => {
                showErrorMessage(error);
            })
    }

    const onSelectedWaiverReasonChange = (e, formRenderProps) => {
        setSelectedWaiverReason(e.target.value);
        formRenderProps.onChange("waiverSubReason", { value: TripConfig.waiverReason.filter(item => item.reason === e.target.value?.value)[0] })
        setSelectedWaiverSubReason(TripConfig.waiverSubReason.filter(item => item.reason === e.target.value?.value)[0]);
    }


    const getWaiverConfig = async () => {
        await axiosService.get(process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.configData + '?key=WAIVER_CONFIG')
            .then(data => {
                if (data?.data?.waiverConfig) {
                    setWaiverConfig(data?.data?.waiverConfig);
                }
            }).catch(error => {
                showErrorMessage(error);
            })
    }

    const getWaiverDetails = async () => {
        setLoading(true);
        await axiosService.get(process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.getWaiverDetails(tripId))
            .then(data => {
                if (data?.data?.waiverDetails) {
                    const waiverData = { ...data?.data?.waiverDetails };
                    const details = { ...waiverData, waiverReason: waiverData?.reason, waiverSubReason: waiverData?.subReason, waiverStatus: waiverDetails?.status }
                    const updatedDetails = getLabelValueObjectFromEnum(['waiverReason', 'waiverSubReason'], details, 'trip');
                    setWaiverDetails(updatedDetails);
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                showErrorMessage(error);
            })
    }

    const calculateWaiverPricing = (percentage) => {
        const waiverAmount = (pricingAmount * ((percentage < waiverConfig?.maxPercentage ? percentage : waiverConfig?.maxPercentage) / 100)).toFixed(2) < waiverConfig?.maxAmount
            ? (pricingAmount * ((percentage < waiverConfig?.maxPercentage ? percentage : waiverConfig?.maxPercentage) / 100)).toFixed(2)
            : waiverConfig?.maxAmount;
        return waiverAmount;
    }


    const handleSubmit = async () => {
        setLoading(true);
        await axiosService.post(process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.saveWaiverDetails(tripId), JSON.stringify(formDataItem),{ 'Content-Type': 'application/json' })
            .then(data => {
                setLoading(false);
                showToastMessage('Waiver details updated successfully');
                getWaiverDetails();
                closeWaiverConfirmModal();
            })
            .catch(error => {
                setLoading(false);
                showErrorMessage(error);
                closeWaiverConfirmModal();
            });
    }

    const openWaiverConfirmModal = (dataItem) => {
        const waiverAmount = calculateWaiverPricing(Number(dataItem?.waiverPercentage));
        const formValues = {
            reason: dataItem?.waiverReason?.value,
            subReason: dataItem?.waiverSubReason?.value,
            remarks: dataItem?.remarks,
            amount: waiverAmount,
            infractionType: dataItem?.waiverSubReason?.tag,
            waiverPercentage: Number(dataItem?.waiverPercentage),
            maxWaiverAmount: waiverConfig?.maxAmount
        }
        setFormDataItem(formValues);
        setModalOpen(true);
    }
    
    const closeWaiverConfirmModal = () => {
        setModalOpen(false);
    }

    return (
        <div className='waivers-tab'>
            {
                loading ? <LoadingPanel /> : (
                    <Form
                        onSubmit={openWaiverConfirmModal}
                        initialValues={waiverDetails}
                        key={JSON.stringify(waiverDetails)}
                        render={(formRenderProps) => (
                            <FormElement
                                style={{
                                    width: '100%'
                                }}
                            >
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Waiver Reason:
                                            </div>
                                            <div className='col-lg-6'>
                                                <Field
                                                    id={"waiverReason"}
                                                    name={"waiverReason"}
                                                    component={FormDropDownList}
                                                    data={TripConfig.waiverReason}
                                                    textField="label"
                                                    dataItemKey="value"
                                                    disabled={waiverDetails}
                                                    onChange={(e) => onSelectedWaiverReasonChange(e, formRenderProps)}
                                                    validator={requiredValidator}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Waiver Sub Reason:
                                            </div>
                                            <div className='col-lg-6'>
                                                <Field
                                                    id={"waiverSubReason"}
                                                    name={"waiverSubReason"}
                                                    component={FormDropDownList}
                                                    data={TripConfig.waiverSubReason.filter(item => item.reason === selectedWaiverReason.value)}
                                                    textField="label"
                                                    dataItemKey="value"
                                                    disabled={waiverDetails}
                                                    validator={requiredValidator}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='row'>
                                            <div className='col-lg-2'>
                                                Remarks:
                                            </div>
                                            <div className='col-lg-10'>
                                                <Field
                                                    id={"remarks"}
                                                    name={"remarks"}
                                                    value={waiverDetails?.remarks}
                                                    component={FormTextArea}
                                                    rows={6}
                                                    disabled={waiverDetails}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Waiver Percentage:
                                            </div>
                                            <div className='col-lg-6'>
                                                <Field
                                                    id={"waiverPercentage"}
                                                    name={"waiverPercentage"}
                                                    value={waiverDetails?.waiverPercentage}
                                                    format={"n0"}
                                                    component={FormNumericTextBox}
                                                    min={1}
                                                    max={100}
                                                    disabled={waiverDetails}
                                                    validator={requiredValidator}
                                                    refP={el => numericTextBoxRef.current=el}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        {
                                            waiverDetails?.status === 'COMPLETED' &&
                                            (
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        Waiver Status:
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <span>{waiverDetails?.status}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <br />
                                {
                                    !waiverDetails && (
                                        <div className='row'>
                                            <div className='col-lg-12 txtcenter'>
                                                <Button
                                                    themeColor={"primary"}
                                                    type={"submit"}
                                                    disabled={!formRenderProps.valid}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </FormElement>
                        )}
                    />
                )
            }
            {modalOpen && <WaiverConfirmModal pricingAmount={pricingAmount} formDataItem={formDataItem} onClose={closeWaiverConfirmModal} handleSubmit={handleSubmit} />}
        </div>
    )
}

export default Waivers