import { toast } from "react-toastify";
import { DriverConfig } from "../config/driverConfig";
import { TripConfig } from "../config/tripConfig";
import FilterDropDown from "../components/common/FilterDropDown";
import { CouponConfig } from "../config/couponConfig";
import FilterMultiSelectDropDown from "../components/common/FilterMultiSelectDropDown";
import { SubscriptionConfig } from "../config/subscriptionConfig";

export const dateAndTimeToRequiredFormat = (date) => {
  const day = prefixZeroToValue(date.getDate());
  const month = prefixZeroToValue(Number(date.getMonth() + 1));
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return [day, month, year, hours, minutes, seconds];
};

export const prefixZeroToValue = (value) => {
  if (String(value)?.length === 1) return "0" + value;
  else return value;
};

export const initialFilter = {
  logic: "or",
};

export const saveToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.log(err);
  }
};

export const getFromLocalStorage = (key) => {
  let val = localStorage.getItem(key);
  try {
    if (val) return JSON.parse(val);
  } catch (err) {
    return null;
  }
  return val;
};

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const showToastMessage = (
  message,
  isSuccess = true,
  autoClose = 3000
) => {
  if (isSuccess) {
    toast.success(message, {
      hideProgressBar: true,
      autoClose: autoClose,
    });
  } else {
    toast.error(message, {
      hideProgressBar: true,
      autoClose: autoClose,
      closeOnClick: true,
    });
  }
};

export const showToastInfoMessage = (message, autoClose = 3000) => {
  toast.info(message, {
    hideProgressBar: true,
    autoClose: autoClose,
    closeOnClick: true,
  });
};

export const showFirebaseErrorMessage = (error) => {
  if (error.code) {
    showToastMessage(
      "Invalid login: " + getRefinedFirebaseAuthErrorMessage(error?.code),
      false
    );
  } else {
    showToastMessage("Invalid login", false);
  }
};

export const getRefinedFirebaseAuthErrorMessage = (errorMesssage) => {
  return errorMesssage.split("/")[1].split("-").join(" ");
};

export const showErrorMessage = (error) => {
  console.log(error);
  if (error && error.response?.data?.error?.message) {
    showToastMessage(error.response?.data?.error?.message, false);
  } else if (error && error.response?.data?.msg) {
    showToastMessage(error.response?.data?.msg, false);
  } else if (error && error.response?.data?.error) {
    showToastMessage(error.response?.data?.error, false);
  } else {
    showToastMessage(error.message, false);
  }
};

export const getImage = (url) => {
  if (url) {
    return url;
  } else {
    return "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
  }
};

export const transformFilters = (columns, { filters }) => {
  const KENDO_FILTER_VALUE_TYPES = ["string", "number", "boolean"];
  const UPPERCASE_COLUMNS = ["fullName", "customerName", "tripId", "driverId"];
  if (!filters) return {};

  let filterParams = filters.reduce((acc, { field, value }) => {
    if (value === "") {
      return acc;
    }
    const regex = /[A-Za-z0-9_]/;
    if (!regex.test(value)) {
      showToastMessage(
        "Special characters are not allowed in filter search",
        false
      );
      return acc;
    }
    const fieldColumn = columns.find((col) =>
      col.field ? field === col.field : field === col.id
    );
    const fieldFilterParam = fieldColumn["filterKey"];
    const fieldFilterType = fieldColumn["filterType"];
    const fieldFilterOperator =
      fieldColumn["defaultFilterOperator"] === "eqIn"
        ? "in"
        : fieldColumn["defaultFilterOperator"];
    acc[fieldFilterParam] = KENDO_FILTER_VALUE_TYPES.includes(typeof value)
      ? (UPPERCASE_COLUMNS.includes(fieldColumn?.field)
          ? stringToUpperCase(value)
          : value) +
        ":" +
        fieldFilterOperator +
        ":" +
        fieldFilterType
      : value.value + ":" + fieldFilterOperator + ":" + fieldFilterType;
    return acc;
  }, {});

  return filterParams;
};

export const convertObjectToParams = (params) => {
  if (typeof params === "string") {
    return params;
  }
  let finalParams = "";
  Object.keys(params).forEach((key, index) => {
    if (index === 0) {
      finalParams += "?";
    }
    finalParams += key + "=" + params[key];
    if (index + 1 !== Object.keys(params).length) {
      finalParams += "&";
    }
  });
  return finalParams;
};

export const getLabelFromEnum = (keys, dataSet, config) => {
  let configFile;
  switch (config) {
    case "trip":
      configFile = TripConfig;
      break;

    case "driver":
      configFile = DriverConfig;
      break;

    case "coupon":
      configFile = CouponConfig;
      break;

    case "subscription":
      configFile = SubscriptionConfig;
      break;

    default:
      break;
  }

  if (configFile) {
    for (const key of keys) {
      if (dataSet[key]) {
        dataSet[key] = configFile[key]?.filter(
          (item) => item.value === dataSet[key]
        )[0]?.label;
      }
    }
    return dataSet;
  }
};

export const getLabelValueObjectFromEnum = (keys, dataSet, config) => {
  let configFile;
  switch (config) {
    case "trip":
      configFile = TripConfig;
      break;

    case "driver":
      configFile = DriverConfig;
      break;

    case "coupon":
      configFile = CouponConfig;
      break;

    default:
      break;
  }

  if (configFile) {
    for (const key of keys) {
      if (dataSet[key] || dataSet[key]?.length > 0) {
        dataSet[key] = configFile[key]?.filter(
          (item) => item.value === dataSet[key]
        )[0];
      } else {
        if (key === "driverPayoutType") {
          dataSet[key] = { label: "Select", value: "" };
        } else {
          dataSet[key] = configFile[key][0];
        }
      }
    }
    return dataSet;
  }
};

export const CategoryFilterCell = (props, column, width) => (
  <FilterDropDown
    {...props}
    {...column}
    data={column.data}
    defaultItem={{ label: "Select Filter", value: "" }}
    width={width}
  />
);

export const CategoryFilterMultiSelectCell = (props, column, width) => (
  <FilterMultiSelectDropDown
    {...props}
    {...column}
    data={column.data}
    width={width}
  />
);

export const convertEnumToLabel = (enumStr) => {
  return enumStr
    .split("_")
    .map((item) => item[0] + item.substring(1).toLowerCase())
    .join(" ");
};

export const convertLabelToEnum = (labelStr) => {
  return labelStr.toUpperCase().split(" ").join("_");
};

export const replaceNullValues = (value, returnValue) => {
  if (value) return value;
  return returnValue ? returnValue : "N.A";
};

export const stringToUpperCase = (value) => {
  if (value) {
    return value?.trim().toUpperCase();
  }
};

export const stringToSentenceCase = (value) => {
  return value
    ?.trim()
    ?.split(" ")
    ?.map(
      (word) =>
        word?.toLowerCase()?.at(0)?.toUpperCase() +
        word?.toLowerCase()?.slice(1)
    )
    ?.join(" ");
};

export const dateToDaysHoursMinutesSeconds = (finalDate, initialDate) => {
  let dateString = "";
  const difference =
    new Date(finalDate).getTime() - new Date(initialDate).getTime();
  const days = new Date(difference).toISOString().slice(8, 10);
  const hoursAndMinutes = new Date(difference)
    .toISOString()
    .slice(11, 19)
    .split(":");
  if (days.toString() !== "01") {
    dateString +=
      Number(days) - 1 === 1
        ? Number(days) - 1 + " day"
        : Number(days) - 1 + " days";
  }
  if (Number(hoursAndMinutes[0]) > 0) {
    dateString +=
      Number(hoursAndMinutes[0]) === 1
        ? hoursAndMinutes[0] + " hour "
        : hoursAndMinutes[0] + " hours ";
  }
  if (Number(hoursAndMinutes[1]) > 0) {
    dateString +=
      Number(hoursAndMinutes[1]) === 1
        ? hoursAndMinutes[1] + " minute "
        : hoursAndMinutes[1] + " minutes ";
  }
  if (Number(hoursAndMinutes[2]) > 0) {
    dateString +=
      Number(hoursAndMinutes[2]) === 1
        ? hoursAndMinutes[2] + " second "
        : hoursAndMinutes[2] + " seconds ";
  }

  return dateString;
};

export const convertMinutesToHoursMinutesFormat = (minutes) => {
  if (minutes === null) return "N.A";
  if (minutes < 60) {
    return minutes + " minutes";
  }
  let hourString = " hour",
    minuteString = " minute";
  let hours = Math.floor(minutes / 60);
  let actualMinutes = minutes % 60;
  if (hours > 1) hourString = " hours";
  if (actualMinutes === 0) return hours + hourString;
  if (actualMinutes > 1) minuteString = " minutes";
  return hours + hourString + " " + actualMinutes + minuteString;
};

export const handleOnWheel = (event) => {
  event.stopPropagation();
};

export const downloadFileFromAPI = (response, name) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = `${url}`;
  link.setAttribute("download", name + ".csv");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const removeExistingFileOnFileUpload = (
  e,
  setFileStateValue,
  inputRef
) => {
  e.target.value = "";
  setFileStateValue("");
  inputRef.current.value = "";
};

export const checkMatchingRoles = (currentRoles, designatedRoles) => {
  const currentRolesSet = new Set(currentRoles);
  const commonRoles = designatedRoles.filter((item) =>
    currentRolesSet.has(item)
  );
  return commonRoles.length > 0;
};

//

export function formatAddress(address) {
  if (address?.formattedAddress) return address?.formattedAddress;
  const {
    houseNo = "",
    locality = "",
    landmark = "",
    city = "",
    state = "",
    pincode = "",
    addressType = "",
  } = address;

  let formattedAddress = "";

  if (houseNo) formattedAddress += houseNo + ", ";
  if (locality) formattedAddress += locality + ", ";
  if (landmark) formattedAddress += "Near " + landmark + ", ";
  if (city) formattedAddress += city + ", ";
  if (state) formattedAddress += state + " ";
  if (pincode) formattedAddress += pincode + ", ";
  // if (addressType) formattedAddress += "(" + addressType + ")";

  formattedAddress = formattedAddress.replace(/,\s*$/, "");

  return formattedAddress;
}

export const timeConverter = (time) => {
  const newtime = new Date(time).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return newtime;
};

export function formatDateTime(dateTimeStr) {
  if (!dateTimeStr) return "N.A.";
  const date = new Date(dateTimeStr);
  const formatHoursMinutes = (hours, minutes) => {
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${period}`;
  };

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return formatHoursMinutes(hours, minutes);
}



export function getLabelByValue(value, array) {
  const statusItem = array.find(item => item.value === value);
  return statusItem ? statusItem.label : null;
}