import React, { useState } from 'react'
import { TripConfig } from '../../../config/tripConfig';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import PricingDiluterConfigurationTable from './PricingDiluterConfigurationTable';

const PricingDiluterConfigurationHome = () => {
  const [selectedTripType, setSelectedTripType] = useState(TripConfig.tripType[0]);
  const [selectedTripSubType, setSelectedTripSubType] = useState(TripConfig.journeyMode[0]);
  const [selectedTripDurationType, setSelectedTripDurationType] = useState(TripConfig.durationType[0]);
  const [tripripDurationData, setTripripDurationData] = useState(TripConfig.durationType);

  const handleTripTypeChange = (event) => {
    getDurationDropdownData(event.target.value?.value,selectedTripSubType?.value,selectedTripDurationType?.value);
    setSelectedTripType(event.target.value);
  };

  const handleTripSubTypeChange = (event) => {
    getDurationDropdownData(selectedTripType?.value,event.target.value?.value,selectedTripDurationType?.value);
    setSelectedTripSubType(event.target.value);
  };

  const handleTripDurationTypeChange = (event) => {
    getDurationDropdownData(selectedTripType?.value,selectedTripSubType?.value,event.target.value?.value)
    setSelectedTripDurationType(event.target.value);
  };

  const getDurationDropdownData = (tripType,tripSubType,duration) => {
    // if(tripType === 'IN_CITY' && tripSubType === 'ONE_WAY'){
    //     setTripripDurationData([{...TripConfig.durationType[0]}]);
    //     setSelectedTripDurationType({...TripConfig.durationType[0]});
    // }else if((tripType === 'OUTSTATION_FAR' && tripSubType === 'ROUND_TRIP') || (tripType === 'OUTSTATION_FAR' && tripSubType === 'ONE_WAY')){
    //     setTripripDurationData([{...TripConfig.durationType[1]}]);
    //     setSelectedTripDurationType({...TripConfig.durationType[1]});
    // }else{
    //     setTripripDurationData([...TripConfig.durationType]);
    // }
    setTripripDurationData([...TripConfig.durationType]);
  }

  return (
    <div className='pricing-config'>
      <div className='row'>
        <div className='col-sm-4'>
          <div className='row'>
            <div className='col-12 d-flex jcsp rate-card'>
              <span className='left mt-1'><h6>Trip Type</h6></span>
              <span className='d-flex'>
                <DropDownList data={TripConfig.derivedTripType} textField='label' dataItemKey='value' value={selectedTripType} onChange={handleTripTypeChange} />
              </span>
            </div>
            <div className='col-12 mt-4 d-flex jcsp rate-card'>
              <span className='left mt-1'><h6>Trip Sub Type</h6></span>
              <span className='d-flex'>
                <DropDownList data={TripConfig.journeyMode} textField='label' dataItemKey='value' value={selectedTripSubType} onChange={handleTripSubTypeChange} />
              </span>
            </div>
            <div className='col-12 mt-4 d-flex jcsp rate-card'>
              <span className='left mt-1'><h6>Trip Duration</h6></span>
              <span className='d-flex'>
                <DropDownList data={tripripDurationData} textField='label' dataItemKey='value' value={selectedTripDurationType} onChange={handleTripDurationTypeChange} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <br />

      <PricingDiluterConfigurationTable derivedTripType={selectedTripType?.value} journeyMode={selectedTripSubType?.value} durationType={selectedTripDurationType?.value} />

    </div>
  )
}

export default PricingDiluterConfigurationHome;