import React, { useEffect, useState } from 'react'
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import PricingConfigurationForm from './PricingConfigurationForm';
import PricingDiluterConfigurationHome from './PricingDiluterConfig/PricingDiluterConfigurationHome';
import PricingChangeLogsTable from './pricing-change-logs/PricingChangeLogsTable';
import { useSelector } from 'react-redux';

const customStyle = {
    padding: '15px',
};

const PricingConfigHome = () => {
    const selectedPricingRegionFilter = useSelector(state => state.region?.pricing);
    const [expanded, setExpanded] = useState([""]);

    useEffect(()=>{
        setExpanded([""]);
    },[selectedPricingRegionFilter])

    const handleSelect = event => {
        if (event.expandedItems) {
            setExpanded(event.expandedItems);
        }
    };

    return (
        <div className='pricing-config-home'>
            <PanelBar isControlled={true} expanded={expanded} onSelect={handleSelect} expandMode={'single'}>
                <PanelBarItem title='Pricing Headers Config'>
                    <div style={customStyle} className="custom-template">
                        <PricingConfigurationForm />
                    </div>
                </PanelBarItem>
                <PanelBarItem title='Pricing Diluter Config'>
                    <div style={customStyle} className="custom-template">
                        <PricingDiluterConfigurationHome />
                    </div>
                </PanelBarItem>
                <PanelBarItem title='Pricing Change Logs'>
                    <div style={customStyle} className="custom-template">
                        <PricingChangeLogsTable />
                    </div>
                </PanelBarItem>
            </PanelBar>
        </div>
    )
}

export default PricingConfigHome