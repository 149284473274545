import React, { useState } from "react";
import CardLayout from "../components/common/CardLayout";
import DriverProfilesTable from "../components/driver-management/driver-profiles/DriverProfilesTable";
import { useLocation, useParams } from "react-router-dom";
import DriverManagementNavigation from "../components/driver-management/DriverManagementNavigation";
import { useDispatch, useSelector } from "react-redux";
import { updateDriverRegionFilter } from "../slices/regionSlice";
import { FormMultiSelect } from "../utils/forms/FormComponents";
import { getFromLocalStorage } from "../utils/helpers";
import { DropdownSearchMultiSelect } from "../utils/forms/NewFormComponents";

const DriverManagement = () => {
  const location = useLocation();
  const { driverId } = useParams();
  const regions = useSelector((state) => state.region?.mainRegions);
  const selectedDriverRegionFilters = useSelector(
    (state) => state.region?.driver
  );
  const dispatch = useDispatch();

  //Datastate for Trip
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams:
      getFromLocalStorage("regionFilters") &&
      getFromLocalStorage("regionFilters")?.driver?.length > 0
        ? {
            region_id:
              getFromLocalStorage("regionFilters")
                ?.driver?.reduce((acc, curr) => (acc += curr?.value + ","), "")
                .slice(0, -1) + ":in:string",
          }
        : {},
  });

  const onDriverRegionFilterChange = (e) => {
    dispatch(updateDriverRegionFilter([...e]));
    if (e.length > 0) {
      const regionQueryStr = e.reduce(
        (acc, curr) => (acc += curr?.value + ","),
        ""
      );
      setDataState({
        ...dataState,
        skip: 0,
        take: 10,
        otherParams: {
          region_id: regionQueryStr.slice(0, -1) + ":in:string",
        },
      });
    } else {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0
      ) {
        setDataState({
          ...dataState,
          skip: 0,
          take: 10,
          otherParams: {},
        });
      }
    }
  };

  const updatedRegions = regions
    ?.slice()
    ?.map((item) => {
      const isSelected = selectedDriverRegionFilters.some(
        (selectedItem) => selectedItem.regionId === item.regionId
      );

      if (isSelected) {
        return null;
      }

      return {
        ...item,
        label: item.child
          ? `${item.regionName} (${item.parentRegionName})`
          : item.regionName,
      };
    })
    .filter((item) => item !== null);

  const DriverRegionFilterDiv = () => (
    // <div
    //   className="region-filter"
    //   style={{ width: "100%", textAlign: "right" }}
    // >
    //   <FormMultiSelect
    //     label={"Regions: "}
    //     value={selectedDriverRegionFilters}
    //     data={regions}
    //     textField="label"
    //     dataItemKey="value"
    //     onChange={onDriverRegionFilterChange}
    //     style={{ width: "80%", margin: "0 10px" }}
    //   />
    // </div>
    <div>
      <DropdownSearchMultiSelect
        options={updatedRegions}
        textField="label"
        values={selectedDriverRegionFilters}
        onChange={onDriverRegionFilterChange}
      />
    </div>
  );

  return (
    <CardLayout
      title="Driver Management"
      ComponentRender={DriverRegionFilterDiv}
    >
      {driverId &&
      ["profile", "account", "online-activity"].includes(
        location.pathname.split("/").at(-1)
      ) ? (
        <DriverManagementNavigation />
      ) : (
        <DriverProfilesTable
          dataState={dataState}
          setDataState={setDataState}
        />
      )}
    </CardLayout>
  );
};

export default DriverManagement;
