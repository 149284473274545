import React, { useState } from 'react'
import { APIConfig } from '../../../utils/constants/api.constants';
import { checkMatchingRoles, downloadFileFromAPI, showErrorMessage, showToastInfoMessage, showToastMessage } from '../../../utils/helpers';
import axiosService from '../../../init/axios';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridLoader from '../../common/GridLoader';
import PayoutRejectReasonModal from './PayoutRejectReasonModal';
import DriverPayoutLevelApproval from './DriverPayoutLevelApproval';
import { useSelector } from 'react-redux';
import { userPermissions } from '../../../config/userPermissionConfig';

const DriverPayoutTable = () => {
    const baseUrl = process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.driverManagement.getDriverPayoutBatch;
    const [loading, setLoading] = useState(false);
    const [payoutLoading, setPayoutLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPayoutBatch, setSelectedPayoutBatch] = useState(null);
    const [refreshTable,setRefreshTable] = useState(false);
    const [driverPayouts, setDriverPayouts] = useState({
        data: [],
        total: 0
    });
    const [dataState, setDataState] = useState({
        take: 10,
        skip: 0,
        otherParams: {
            status: 'CREATED'
        }
    });
    const userRoles = useSelector(state => state.auth?.userRoles);

    const DriverPayoutCell = (props) => (
        <div onClick={(e) => onDriverPayoutClick(e,props.dataItem?.batchId)} style={{ cursor: 'pointer', color: 'blue', margin: '20px 10px' }}>{props.dataItem?.batchId}</div>
    );

    const PayoutActionCell = (props) => (
        <>
            <Button themeColor={'primary'} disabled={!checkMatchingRoles(userRoles,userPermissions.payoutModification) || loading} onClick={(e) => onApprovePayout(props?.dataItem?.batchId, true)}>Approve</Button>
            <Button themeColor={'primary'} disabled={!checkMatchingRoles(userRoles,userPermissions.payoutModification) || loading} onClick={(e) => openReasonModal(props?.dataItem?.batchId)}>Reject</Button>
        </>
    );

    const columns = [
        {
            id: "batchId",
            title: "File",
            cell: DriverPayoutCell
        },
        {
            id: "createdAt",
            field: "createdAt",
            title: "Created At",
            filterable: false
        },
        {
            id: "action",
            title: "Action",
            cell: PayoutActionCell
        }

    ];

    const dataStateChange = e => {
        setDataState({ ...e.dataState, otherParams: { status: 'CREATED' } });
    };
    const dataReceived = dataObj => {
        setDriverPayouts({ ...dataObj, data: dataObj?.data?.data });
    };

    const onRefreshPayoutTable = () => {
        setRefreshTable(true);
        setDataState({ ...dataState, otherParams: { status: 'CREATED' } });
    }

    const onDriverPayoutClick = async (e,id) => {
        if(payoutLoading){
            return;
        }
        showToastInfoMessage('Downloading payout...');
        await axiosService.get(process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.driverManagement.getDriverPayoutBatchFile(id),'',{},'blob')
            .then(response => {
                downloadFileFromAPI(response?.data,id);
                showToastMessage('Payout downloaded successfully');
                setPayoutLoading(false);
            }).catch(error => {
                showErrorMessage(error);
                setPayoutLoading(false);
            });
    }

    const onApprovePayout = async (id, approveFlag, reason) => {
        setLoading(true);
        let payload;
        if(approveFlag){
            payload={
                "action":"APPROVE"
            }
        }else{
            payload={
                "action":"REJECT",
                "rejectionRemarks":reason
            }
        }
        await axiosService.put(process.env.REACT_APP_DRIVER_BASE_URL + APIConfig.driverManagement.approvePayoutBatch(id), JSON.stringify(payload))
            .then(res => {
                setLoading(false);
                showToastMessage(`Driver Payout ${approveFlag?'approved':'rejected'} successfully`);
                onRefreshPayoutTable();
            })
            .catch(error => {
                setLoading(false);
                showErrorMessage(error);
            });
            setSelectedPayoutBatch(null);
            closeReasonModal();
    }

    const openReasonModal = (id) => {
        setSelectedPayoutBatch(id);
        setModalOpen(true);
    }

    const closeReasonModal = () => {
        setModalOpen(false);
    }

    return (
        <>
            <Grid filterable={false} sortable={false} pageable={true} {...dataState}
                data={driverPayouts}
                onDataStateChange={dataStateChange}>
                {
                    columns.map(column => (
                        <Column key={column?.id} field={column?.field} title={column?.title} cell={column?.cell} width={column?.width} />
                    ))
                }
            </Grid>
            <br /><br />
            <DriverPayoutLevelApproval onRefreshPayoutTable={onRefreshPayoutTable} />
            <br />
            <GridLoader baseUrl={baseUrl} columns={columns} dataState={dataState} onDataReceived={dataReceived} refreshTable={refreshTable} />
            {modalOpen && <PayoutRejectReasonModal loading={loading} selectedPayoutBatch={selectedPayoutBatch} onClose={closeReasonModal} onApprovePayout={onApprovePayout} />}
        </>
    )
}

export default DriverPayoutTable;